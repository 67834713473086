$white : #ffffff;
$black : #000000;
$almost-black : #141414;
$light : #C0C5CE;
$gray : #F3F4F7;

$light-gray-30: #AEAFB0;
$light-gray-40: #9CA1AA;

$blue_main : #3B83FB;
$blue : $blue_main;
$blue_light : #3576E2;
$blue_hover : $blue_light;
$blue_disabled : #B4C5E4;
$cyan : #22C6FC;
$violet : #AE6CFC;
$green: #159310;
$red: #D3280D;
$yellow: #FDB024;

// elements
$border : #c4c4c4;
$font : #5A6372;
$footer : #818B9C;
$footer-link : #DADFE7;
$toc-border: #D1D5DB;
$pagination: #E6E9EF;

$hero-subtitle : rgba($white, 0.7);
$post-title : #26282C;

$tabs : #98A0AE;
$tag : $tabs;
$devicebar: #F9FAFB;

// links
$link-active: $black;

// gradients
$gradient-1: linear-gradient(180deg, #22C6FC 0%, #3B83FB 100%);
$gradient-2: linear-gradient(180deg, #3B83FB 0%, #AE6CFC 100%);
$gradient-3: radial-gradient(66.17% 65.5% at 98.33% 98.16%, #0F1E30 0%, #040812 100%);
$gradient-4: radial-gradient(102.34% 94.34% at 102.34% 90%, rgba(18, 35, 55, 0.3) 0%, rgba(1, 2, 10, 0.3) 100%);
$gradient-5: radial-gradient(64.41% 57.19% at 73.45% 65.79%, #122337 0%, #01020A 100%);
$gradient-6: linear-gradient(90deg, #22C5FC 1.51%, #3B83FB 50.22%, #AD6CFC 99.96%);
$gradient-7: radial-gradient(90.63% 95.19% at 93.03% 81.64%, #353840 0%, #0D0E14 82.62%, #101328 100%);
$gradient-startscreen: linear-gradient(142.45deg, #3E5775 -10.99%, #030713 97.56%);


.gradient {
    &_1 {
        background: $gradient-1
    }

    &_2 {
        background: $gradient-2
    }

    &_3 {
        background: $gradient-3
    }

    &_4 {
        background: $gradient-4
    }

    &_5 {
        background: $gradient-5
    }

    &_6 {
        background: $gradient-6
    }

    &_7 {
        background: $gradient-7
    }

    &_startscreen {
        background: $gradient-startscreen
    }
}

.gradient_text {
    display: inline-block;
    background-color: $blue_main;
    background-image: linear-gradient(90deg, $cyan 1.51%, $blue_main 50.22%, $violet 99.96%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.blue,
.blue_main {
    color: $blue !important
}

.cyan {
    color: $cyan !important;
}

.violet {
    color: $violet !important;
}

.white {
    color: $white !important;
}