body {
    min-height: 100vh;
    @include flex(column, flex-start, center);
}

header {
    width: 100%;
}

footer {
    width: 100%;
    margin-top: auto;
}

main {
    width: 100%;
    @include flex(column, flex-start, center);
}

.container {
    $x-padding: 40px;

    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: calc(1520px + #{$x-padding} * 2);
    padding: 40px $x-padding;
    margin-left: auto;
    margin-right: auto;

    @include less-than('xl') {
        $x-padding: 20px;

        padding: 32px $x-padding;
    }

    header+main>&:first-child {
        padding-top: 0;
    }

    &_zero-bottom {
        padding-bottom: 0 !important;

        &-lg {
            @include less-than('lg') {
                padding-bottom: 0 !important;
            }
        }
    }

    &_zero-top {
        padding-top: 0 !important;

        &-lg {
            @include less-than('lg') {
                padding-top: 0 !important;
            }
        }
    }

    &_regular-bottom,
    header+main>&_regular-bottom.container {
        padding-bottom: 40px;
    }

    &_regular-top,
    header+main>&_regular-top.container {
        padding-top: 40px;
    }

    &_double-bottom,
    header+main>&_double-bottom.container {
        padding-bottom: 80px;
    }

    &_double-top,
    header+main>&_double-top.container {
        padding-top: 80px;
    }

    &_100-bottom {
        padding-bottom: 100px !important;

        @include less-than('lg') {
            padding-bottom: 56px !important;
        }
    }

    &_100-top {
        padding-top: 100px !important;

        @include less-than('lg') {
            padding-top: 56px !important;
        }
    }

    &_200-bottom {
        padding-bottom: 200px !important;

        @include less-than('sm') {
            padding-bottom: 80px !important;
        }
    }

    &_200-top {
        padding-top: 200px !important;

        @include less-than('sm') {
            padding-top: 80px !important;
        }
    }

    &__nested {
        width: 100%;
        max-width: calc(1520px / 3 * 2);
        margin-left: auto;
        margin-right: auto;
    }
}

.container_wide {
    max-width: 1640px;

    @include less-than('xxxl') {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    @include less-than('xl') {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }


    // @include less-than('md'){
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    // }

}

.container_full {
    $x-padding: 40px;
    max-width: none;
}