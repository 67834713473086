a {
    @include font(inherit, inherit, inherit, $font);
    transition: color 0.3s ease-out;

    &:hover {
        color: $blue
    }
}

b {
    font-weight: 700;
}

.semibold {
    font-weight: 600 !important;
    color: $black  !important;
}

.bold {
    font-weight: 700 !important;
    color: $black  !important;
}

.link {
    @include font(inherit, inherit, inherit, $blue);
    transition: $transition;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 0;
    background: transparent;

    &:hover {
        color: $blue_hover;
        background: transparent;
    }

    &_underline {
        color: $blue_main;
        border-bottom: 1px solid $blue_main;
    }

    &_danger {
        color: #D3280D !important;
    }

    &_success {
        color: #159310 !important;
    }

    &_coming-soon {
        color: $light-gray-30  !important;
        letter-spacing: -0.02em;
    }
}


p,
.p {
    @include font(18px, 28px, 400, $font);

    @include less-than('lg') {
        font-size: 16px;
    }

    & b {
        color: $black
    }
}

span {
    @include font(inherit, inherit, inherit, inherit);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter', sans-serif;

    &>a {
        @include font(inherit, inherit, inherit, inherit)
    }
}

.display {
    @include font(96px, 115%, 700, $black);
    @include font-responsive('xxxl', 'xl', 96, 56, 115%);
    @include font-responsive('xl', 'md', 80, 56, 115%);
    @include font-responsive('md', 'xs', 56, 40, 120%);

    letter-spacing: -0.02em;

    &_md {
        @include font(96px, 108px, 700, $black);

        @include less-than('xxl') {
            @include font(84px, 96px, 700, $black);
        }

        @include less-than('xl') {
            @include font(64px, 76px, 700, $black);
        }

        @include less-than('lg') {
            @include font(56px, 64px, 700, $black);
        }

        @include less-than('md') {
            @include font(48px, 58px, 700, $black);
        }
    }
}

.title--tiles-reveal {
    &>span {
        display: inline-block;
        overflow: hidden;
        line-height: inherit;

        &>span {
            display: inline-block;
            line-height: inherit;
            transform: translateY(200px);
        }
    }
}

.banner-title {
    @include font(120px, 120px, 500, $black);
    letter-spacing: -0.02em;

    @include less-than('lg') {
        @include font(96px, 96px, 500, $black);
    }

    @include less-than('md') {
        @include font(80px, 80px, 500, $black);
    }

    @include less-than('md') {
        @include font(56px, 56px, 500, $black);
    }

    &_sm {
        @include font(80px, 96px, 500, $black);

        @include less-than('xxl') {
            @include font(64px, 76px, 500, $black);
        }

        @include less-than('xl') {
            @include font(56px, 64px, 500, $black);
        }

        @include less-than('lg') {
            @include font(48px, 58px, 500, $black);
        }

        @include less-than('md') {
            @include font(40px, 48px, 500, $black);
        }

    }
}

.h1 {


    @include font(96px, 120px, 700, $almost-black);

    @include less-than('lg') {
        @include font(84px, 96px, 700, $almost-black);
    }

    @include less-than('md') {
        @include font(56px, 64px, 700, $almost-black);
    }

    .customer-portal & {
        @include less-than('lg') {
            @include font(56px, 72px, 700, $almost-black);
        }

        @include less-than('md') {
            @include font(40px, 48px, 700, $almost-black);
        }
    }
}

.h2 {

    .developer-portal & {
        @include font(50px, 120%, 700, $black);
        @include font-responsive('xxxl', 'lg', 50, 32, 120%);
        @include font-responsive('lg', 'xs', 50, 24, 120%);

        &>* {
            @include font(50px, 120%, 700, $black);
            @include font-responsive('xxxl', 'lg', 50, 32, 120%);
            @include font-responsive('lg', 'xs', 50, 24, 120%);
        }
    }

    & a:hover {
        color: $blue_main !important;
    }

    &_white {
        color: $white !important
    }

    .customer-portal & {
        @include font(64px, 80px, 700, $black);

        @include less-than('xl') {
            @include font(56px, 64px, 700, $black);
        }

        @include less-than('lg') {
            @include font(48px, 58px, 700, $black);
        }

        @include less-than('md') {
            @include font(40px, 48px, 700, $black);
        }

        @include less-than('sm') {
            @include font(32px, 40px, 700, $black);
        }
    }
}

.h3 {
    @include font(28px, 38px, 700, $font);

    @include less-than('md') {
        @include font(24px, 28px, 700, $font);
    }

    &_black {
        color: $black;
    }

    &_gray {
        color: $footer;
    }

    & a:hover {
        color: $blue_main;
    }

    .customer-portal & {
        @include font(48px, 72px, 700, $almost-black);

        @include less-than('xxl') {
            @include font(32px, 48px, 700, $almost-black);
        }
    }
}

.h4 {
    @include font(32px, 48px, 800, $almost-black);
    letter-spacing: -0.02em;

    @include less-than('lg') {
        @include font(24px, 32px, 800, $almost-black);
    }

    @include less-than('sm') {
        @include font(20px, 32px, 700, $almost-black);
    }

    .developer-portal & {
        @include font(28px, 38px, 700, $black);
    }
}

.h5 {
    @include font(21px, 25px, 600, $black);

    .customer-portal & {
        @include font(24px, 32px, 600, $almost-black);
        letter-spacing: -0.02em;

        @include less-than('xl') {
            @include font(20px, 28px, 700, $almost-black);
        }
    }
}

// .subtitle {
//     @include font(24px, 28px, 300, $font);

//     @include less-than('md') {
//         @include font(21px, 25px, 300, $font);
//     }

//     @include less-than('sm') {
//         @include font(18px, 21px, 300, $font);
//     }
// }

.p1 {
    @include font(28px, 40px, 400, $font);

    @include less-than('xxxl') {
        @include font(24px, 40px, 400, $font);
    }

    @include less-than('lg') {
        @include font(20px, 32px, 400, $font);
    }

    @include less-than('sm') {
        @include font(18px, 28px, 400, $font);
    }

    & b {
        color: $black
    }

}

.p-24 {
    @include font(24px, 36px, 400, $font);

    @include less-than('lg') {
        @include font(20px, 30px, 400, $font);
    }

    @include less-than('md') {
        @include font(18px, 28px, 400, $font);
    }

}

.p2 {
    @include font(20px, 32px, 400, $font);

    @include less-than('lg') {
        @include font(18px, 28px, 400, $font);
    }

    @include less-than('sm') {
        @include font(16px, 28px, 400, $font);
    }

    & b {
        color: $black
    }
}

.p3 {
    @include font(16px, 22px, 400, $font);

    & b {
        color: $black
    }
}

.p4 {
    @include font(14px, 24px, 400, $light-gray-40);
}

a.p4 {
    &:hover {
        color: #3F4550;
    }

    &:active {
        color: $black;
    }

    &:disabled {
        color: #AEAFB0;
    }
}

.a1 {
    @include font(48px, 72px, 600, $almost-black);

    @include less-than('xl') {
        @include font(32px, 48px, 600, $almost-black);
    }

    @include less-than('lg') {
        @include font(28px, 42px, 600, $almost-black);
    }

    .page-template-customer-homepage section.container>& {
        margin-bottom: 56px;

        @include less-than('xl') {
            @include font(48px, 72px, 600, $almost-black);
        }

        @include less-than('lg') {
            margin-bottom: 48px;
            @include font(56px, 72px, 600, $almost-black);
        }

        @include less-than('md') {
            margin-bottom: 56px;
            @include font(48px, 56px, 600, $almost-black);
        }

        @include less-than('sm') {
            margin-bottom: 32px;
            @include font(40px, 48px, 700, $almost-black);
        }
    }
}

.a2 {
    @include font(28px, 40px, 600, $black);

    @include less-than('xl') {
        @include font(20px, 32px, 600, $black);
    }

    @include less-than('lg') {
        @include font(18px, 30px, 600, $black);
    }

    @include less-than('sm') {
        @include font(16px, 28px, 600, $black);
    }

}

.a3 {
    @include font(20px, 32px, 600, $almost-black);

    @include less-than('xl') {
        @include font(16px, 28px, 600, $almost-black);
    }

    @include less-than('lg') {
        @include font(20px, 32px, 600, $almost-black);
    }

    @include less-than('md') {
        @include font(16px, 28px, 600, $almost-black);
    }
}

.f1 {
    @include font(18px, 32px, 300, $almost-black);
}

.t1 {
    @include font(35px, 42px, 700, $black);

    @include less-than('lg') {
        @include font(28px, 40px, 700, $black);
    }

    @include less-than('sm') {
        @include font(20px, 32px, 700, $black);
    }
}

.t2 {
    letter-spacing: -0.02em;

    @include font(32px, 55px, 500, $font);

    @include less-than('lg') {
        @include font(28px, 40px, 500, $font);
    }

    @include less-than('sm') {
        @include font(20px, 32px, 500, $font);
    }
}

.cta1 {
    @include font(56px, 72px, 700, $almost-black);

    @include less-than('xl') {

        @include font(48px, 72px, 700, $almost-black);
    }

    @include less-than('md') {
        @include font(40px, 48px, 700, $almost-black);
    }

    @include less-than('sm') {
        @include font(32px, 48px, 700, $almost-black);
    }
}

.s1 {
    @include font(18px, 28px, 400, $font);
}

.p-18 {
    font-size: 18px;
    font-weight: 500;
}

.w-600 {
    font-weight: 600;
}

ul {
    & li {

        @include font(inherit, inherit, inherit, inherit);
        text-align: left;

        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }

        & b {
            font-weight: 600;
            color: $black;
        }


    }

    &.circles {
        list-style: none !important;

        & li {
            position: relative;
            padding-left: 32px;

            &::before {
                content: url(#{$path}/assets/icons/plus-li.svg);
                height: 8px !important;
                width: 8px !important;
                left: 0;

                position: absolute;
                top: 4px;
                transition: $transition;
            }

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        &--white {
            & li {
                &::before {
                    content: url(#{$path}/assets/icons/plus-li-white.svg);
                }
            }
        }

        &--gray {
            & li {
                &::before {
                    content: url(#{$path}/assets/icons/plus-li-gray.svg);
                }
            }
        }
    }
}


ul.ul-with-pluses {
    margin-top: 24px;

    & li {
        margin-bottom: 24px;


        @include less-than('xxl') {
            margin-bottom: 16px;
        }

        @include less-than('lg') {
            margin-bottom: 8px;
        }

        &::before {
            height: 16px !important;
            width: 16px !important;
            position: absolute;
            top: 9px;
            transform: translateY(-50%);
            transition: $transition;
        }

    }
}

.footer_customer nav.footer__menu a[target="_blank"] {
    position: relative;
    padding-right: 24px;

    &::after {
        content: url(#{$path}/assets/icons/target-blank.svg);
        height: 20px !important;
        width: 20px !important;
        top: 5px;
        left: 4px;
        position: relative;
        transition: $transition;
    }
}

.color {
    &_primary {
        color: $blue  !important;
    }

    &_danger {
        color: #D3280D !important;
    }

    &_success {
        color: #159310 !important;
    }

    &_font {
        color: $font  !important;
    }

    &_footer {
        color: $footer  !important;
    }

    &_warning {
        color: #FDB024 !important;
    }

    &_light-gray-40 {
        color: $light-gray-40  !important;
    }

    &_white {
        color: rgba($color: $white, $alpha: 1.0) !important;

        &--opacity-80 {
            color: rgba($color: $white, $alpha: 0.8) !important;
        }

        &--opacity-70 {
            color: rgba($color: $white, $alpha: 0.7) !important;
        }
    }
}