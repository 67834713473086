.loop{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;

    &_posts{
        row-gap: 30px;

        .customer-portal &{
            row-gap: 48px;

            @include less-than('xl'){
                row-gap: 40px;
            }

            @include less-than('lg'){
                row-gap: 32px;
            }

            @include less-than('md'){
                row-gap: 40px;
            }

            @include less-than('sm'){
                row-gap: 32px;
            }
        }

        @include less-than('md') {
            grid-template-columns: repeat(1, 1fr) !important;
        }
    }

    &_faqs{
        row-gap: 0;
    }

    &_1{
        grid-template-columns: repeat(1, 1fr);

        &-lg{
            @include less-than('lg') {
                grid-template-columns: repeat(1, 1fr) !important;
            }
        }

        &-md{
            @include less-than('md') {
                grid-template-columns: repeat(1, 1fr) !important;
            }
        }
        
    }

    &_2{
        grid-template-columns: repeat(2, 1fr);

        @include less-than('sm') {
            grid-template-columns: repeat(1, 1fr);
        }

        &-xl{
            @include less-than('xl'){
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &_3{
        grid-template-columns: repeat(3, 1fr);

        @include less-than('lg') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include less-than('sm') {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &_4{
        grid-template-columns: repeat(4, 1fr);

        @include less-than('xl') {
            grid-template-columns: repeat(3, 1fr);
        }
        @include less-than('lg') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include less-than('sm') {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &_5{
        grid-template-columns: repeat(5, 1fr);

        @include less-than('xl') {
            grid-template-columns: repeat(3, 1fr);
        }
        @include less-than('lg') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include less-than('sm') {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &_6{
        grid-template-columns: repeat(6, 1fr);

        @include less-than('xl') {
            grid-template-columns: repeat(3, 1fr);
        }
        @include less-than('lg') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include less-than('sm') {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &_7{
        grid-template-columns: repeat(7, 1fr);

        @include less-than('xl') {
            grid-template-columns: repeat(3, 1fr);
        }
        @include less-than('lg') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include less-than('sm') {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    

}

.pagination{
    margin-top: 56px;
    & .screen-reader-text{
        display: none
    }

    & .nav-links{
        @include flex(row, center);

        & .page-numbers{
            display: inline-block;
            text-align: center;
            width: 48px;
            height: 48px;
            background-color: $white;
            @include font(18px, 48px, 400, $font);
            margin-left: 4px;
            margin-right: 4px;
            border-radius: 50%;
    
            &.current,
            &:hover{
                background-color: $devicebar;
                color: $almost-black;
            }
    
            &.next,
            &.prev{
                display:none
            }
        }
    } 
}