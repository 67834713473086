.select2 {
    &-container {

        &--open {
            & .select2-selection--single span.select2-selection__rendered {
                color: $blue_light !important;
            }

            .select2-dropdown {
                &--below {
                    transform: translateY(8px);
                }

                &--above {
                    transform: translateY(-8px);
                }

                border: none;
                outline: none;
                // cursor: pointer;

                background-color: $gray;
                border-radius: 12px;
            }
        }

        &--default {


            span.select2-selection {

                &__arrow {
                    height: 100%;
                    top: 0;
                    right: 8px;
                }

                &:hover {
                    color: $blue;
                }

                &:active,
                &:focus-within {
                    color: $blue_light;
                }

                &--single {
                    padding: 17.5px 40px 17.5px 16px;
                    margin: 0;
                    height: auto;

                    position: relative;
                    border: none;
                    outline: none;
                    cursor: pointer;

                    background-color: $gray;
                    border-radius: 12px;
                    transition: $transition;

                    & span.select2-selection__rendered {
                        transition: $transition;

                        &:hover {
                            color: $blue !important;
                        }

                    }
                }
            }

        }

        &--disabled{

            & span.select2-selection--single span.select2-selection__rendered{
                color: rgba($color: $font, $alpha: 1) !important;

                &:hover{
                    color: rgba($color: $font, $alpha: 1) !important;
                }
            }

            & .select2-selection__arrow{
                opacity: 0;
            }
        }
        

        & .select2-results {
            border-radius: 12px;

            & ul li{
                margin-bottom: 0;
            }

            &__options {
                border-radius: 12px;
            }

            &__option {
                padding: 8px 16px;
                @include font(18px, 21px, 400, $font);

                &:last-child {
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }

                &:first-child {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                }
            }
        }
    }

    &-container--default.select2-container--open.select2-container--below span.select2-selection--multiple,
    &-container--default.select2-container--open.select2-container--below span.select2-selection--single {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

}

.select2-hidden-accessible+span.select2-container {
    width: 100% !important;
}

span.select2-search {
    &--dropdown {
        padding: 16px 16px 8px 16px;

        & .select2-search__field{
            padding: 8px 16px;
            border-radius: 12px;
        }
    }


}

.select2-container--default .select2-selection--single span.select2-selection__arrow {
    height: 100%;
    top: 0;
    right: 8px;
}

.select2-container--default .select2-selection--single span.select2-selection__rendered {
    @include font(18px, 21px, 400, $font);
    padding: 0;
}

.select2-container--default li.select2-results__option--selectable {
    background-color: $gray;
    color: $font;

    &.select2-results__option--highlighted {
        background-color: $pagination;
        color: $font;
    }
}

.select2-container--default li.select2-results__option--selected {
    background-color: $pagination !important;
    color: $blue !important;
}

.select2-container--default li.select2-results__option--hightlighted {
    background-color: $pagination;
    color: $font;
}

.select2-container--open span.select2-dropdown--below {
    border-radius: 12px;
}

.select2-container .select2-results__option--highlighted {
    background-color: $pagination;
    color: $blue;
}

select{
    transition: $transition;
    @include font(18px, 21px, 400, $font);

    border-radius: 12px;
    background-color: $gray ;
    border: none;
    outline: none;
    appearance: none;
    cursor: pointer;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    padding-right: 40px;
    padding-left: 16px;
    position: relative;

    &:hover{
        color: $blue;
    }
    &:active,
    &:focus-within{
        color: $blue_light;
    }
}

.select2-container--default span.select2-results>.select2-results__options{
    max-height: 250px;
}