#confluence-viewport {
    width: 100%;

    & * {
        font-family: 'Inter', sans-serif;
    }

    @include flex(row, space-between, stretch);

    & header {
        padding-top: 0 !important;
    }

    & #ht-sidebar {

        position: relative;
        top: 0;
        min-height: 50vh;
        height: calc(100% - 15px);
        background: $white;
        border: 1px solid $light;

        @include rounded();
    }

    & #ht-wrap-container {
        left: 0 !important;
        flex-basis: 100%;

        & .ht-content {
            padding: 0;
            padding-left: 32px;
        }
    }

    .confluence-information-macro {
        display: block;
        width: fit-content;
        margin-top: 24px;
        margin-bottom: 24px;
        padding: 32px;
        padding-left: 80px;

        background-color: $gray;
        border: 1px solid $light;
        @include rounded;
        position: relative;

        &::before {
            position: absolute;
            left: 32px;
            top: 30px;
            content: url(#{$path}/assets/icons/alert-tip.svg);
            height: 32px !important;
            width: 32px !important;
        }

        & p {
            margin-top: 0;
            margin-bottom: 0;
        }

        &-tip {
            &::before {
                content: url(#{$path}/assets/icons/alert-tip.svg);
            }
        }

        &-note {
            &::before {
                content: url(#{$path}/assets/icons/alert-warning.svg);
            }
        }

        &-warning {
            &::before {
                content: url(#{$path}/assets/icons/alert-danger.svg);
            }
        }
    }

    .confluence-embedded-image {
        border: 1px solid $gray;
        border-radius: 12px;

        &.confluence-thumbnail {
            border-radius: 4px;
            // transform: translateY(20%);
            display: inline-block;
            margin-left: 6px;
            margin-right: 6px;
        }
    }

    .panel {
        margin-top: 24px;
        margin-bottom: 24px;

        max-width: 100%;
        overflow-x: auto;

        padding: 16px 32px;
        background-color: $white;
        border: 2px solid $blue;
        border-radius: 12px;

        & .panelHeader {
            background-color: $white  !important;
            @include font(28px, 38px, 500, $black);

        }

    }

    .gutenberg-content {
        & h1 {
            @include font(32px, 40px, 600, $black);
        }

        & h2 {
            @include font(28px, 38px, 600, $black);
            margin-top: 24px;
        }

        & h3 {
            @include font(24px, 32px, 600, $black);
            margin-top: 24px;
        }
    }
}

.documentation {
    &-doxygen {

        @include less-than('lg') {
            flex-direction: column !important;
        }

        & #nav-path,
        & #splitbar,
        & #nav-sync,
        & #top,
        & #MSearchResultsWindow,
        & #MSearchSelectWindow {
            display: none !important;
        }

        & #side-nav {
            flex: 0 0 385px;
            max-width: 385px;
            padding-right: 20px;

            @include less-than('xl') {
                min-width: 285px;
                max-width: 285px;
            }

            @include less-than('lg') {
                min-width: 100%;
                max-width: 100%;

                margin-bottom: 30px;
            }

            position: relative;

            & #nav-tree {
                position: sticky !important;

                top: 80px;

                border: 1px solid $tabs;
                @include rounded;
                padding: 16px;


                & #nav-tree-contents {
                    padding-right: 8px;
                    max-height: 400px;
                    overflow-x: hidden;

                    &::-webkit-scrollbar-thumb {
                        background-color: $light;
                        border: 4px solid transparent;
                        border-radius: 12px;
                        background-clip: padding-box;
                    }

                    &::-webkit-scrollbar {
                        width: 16px;
                    }
                }



                & li .item {
                    margin-top: 12px;
                    margin-bottom: 12px;
                    display: flex;

                    & .label {
                        padding-left: 8px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        & a:hover {
                            color: $blue;
                        }
                    }

                    & .arrow {
                        color: $light;
                        display: inline-block;
                        flex-shrink: 0;

                        transition: $transition;

                        &:hover {
                            color: $blue;
                        }
                    }

                    & a {
                        @include font(18px, 28px, 400, $font);
                        text-decoration: none;
                    }

                    &.selected {
                        & .label a {
                            color: $black;
                        }

                    }
                }
            }
        }

        & #doc-content {
            width: calc(100% - (385px + 20px));
            margin-right: auto;
            margin-left: auto;

            @include less-than('xl') {
                width: calc(100% - (285px + 20px));
            }

            @include less-than('lg') {

                width: 100%;

            }

            height: 100% !important;
            margin-left: 0 !important;
        }

        & a {
            color: $blue;

            &.el {
                font-weight: 600;
            }
        }

        // Doxygen
        .header {
            & .summary {
                text-align: right;
                color: transparent;
                margin-bottom: 24px;

                & a {
                    @include font(18px, 21px, 400, $blue);

                    &:hover {
                        color: $blue_hover;
                    }

                    text-decoration: none;
                    margin-left: 24px;
                }
            }

            & .headertitle {


                @include font(35px, 42px, 700, $black);

                & .title {
                    margin-bottom: 16px;
                }

                & .ingroups {
                    margin-bottom: 16px;
                    margin-top: 16px;

                    & a {
                        @include font(28px, 38px, 700, $blue);
                        text-decoration: none;
                    }
                }
            }
        }

        & .contents {

            @include font(18px, 28px, 400, $font);

            @include less-than('sm') {
                @include font(16px, 28px, 400, $font);
            }

            p,
            .textblock {
                margin-top: 16px;
                margin-bottom: 16px;
                @include font(18px, 28px, 400, $font);

                @include less-than('sm') {
                    @include font(16px, 28px, 400, $font);
                }
            }

            & h1,
            & .groupheader {
                @include font(32px, 40px, 600, $black);
                margin-top: 24px !important;
                margin-bottom: 16px !important;
            }

            & h2 {
                @include font(28px, 38px, 600, $black);
                margin-top: 24px;
                margin-bottom: 16px;
            }

            & h3 {
                @include font(24px, 32px, 600, $black);
                margin-top: 24px;
                margin-bottom: 16px;
            }

            & table {
                min-width: 0;
                display: block;

                tr.heading {
                    border-bottom: 2px solid #000000;

                    & td {
                        padding: 0;
                    }
                }


                & td.memItemLeft {
                    @include font(21px, 25px, 600, $black);
                    font-family: 'Source Code Pro', monospace !important;
                    white-space: nowrap;

                    & a {
                        font-family: 'Source Code Pro', monospace !important;
                    }
                }

                & td.memItemRight {
                    font-family: 'Source Code Pro', monospace !important;
                    width: 100%;

                    & a {
                        font-family: 'Source Code Pro', monospace !important;
                    }
                }

                & td.mdescLeft {
                    border-bottom: 0;
                }

                & td.mdescRight {
                    border-bottom: 0;
                }

                & td.memSeparator {
                    padding-top: 0px;
                    padding-bottom: 0px;
                    width: 100%;
                    background: $gray;
                    border-bottom: 0;
                }

                tr:last-child {
                    & td.memSeparator {
                        border-bottom: 0;
                    }
                }
            }

            & code {
                white-space: pre-wrap;
                @include font(inherit, inherit, 500, $almost-black);

                font-family: 'Source Code Pro', monospace !important;

                padding: 2px 8px;

                background-color: $gray;
                border: 1px solid $light;
                border-radius: 8px;

                & * {
                    @include font(inherit, inherit, 500, $almost-black);

                }
            }



            & .fragment {
                margin-bottom: 16px;
                margin-top: 16px;
                background-color: $gray;
                border: 1px solid $light;
                border-radius: 8px;
                overflow-x: auto;

                max-width: 100%;

                & * {
                    @include font(16px, 20px, 400, $almost-black);
                    font-family: 'Source Code Pro', monospace !important;
                }


                padding: 16px 24px;

                & .line {
                    white-space: pre-wrap;
                    padding-top: 4px;
                    padding-bottom: 4px;

                    & .keyword {
                        color: rgb(187, 107, 178);
                    }

                    & a {
                        color: $blue;
                        text-decoration: none;
                    }

                    & .lineno {
                        padding: 6px 16px;
                        width: 45px;
                        display: inline-block;
                        margin-right: 25px;
                        text-align: right;
                        border-right: 1px solid $almost-black;
                    }
                }

                & .ttc {
                    display: none;
                }
            }

            & .memtitle {
                display: inline-block;
                background-color: transparent;

                color: $black;
                font-weight: 700;

                padding: 0;
                margin-bottom: 32px;

                border: 0;


                &::before {
                    display: none;
                }

                & .permalink {
                    display: none;
                }
            }

            & .memitem {
                border: 0;
                margin-bottom: 32px;

                & .memproto {
                    background-color: $gray;
                    padding: 32px;

                    border: 1px solid $light;

                    table.memname {

                        & * {
                            font-family: 'Source Code Pro', monospace !important;
                            color: $font;
                        }

                        & a {
                            color: $blue;

                            &:hover {
                                color: $blue_hover;
                            }
                        }

                        & tbody {
                            border: 0;

                            & td {
                                background-color: $gray;
                                border: 0;
                                padding: 0 8px;

                            }
                        }
                    }

                }


                & .memdoc {
                    padding: 0;
                    margin: 16px 0;

                    & table.params {
                        min-width: 50%;

                        & td {
                            border: 0;
                            padding: 4px 16px 4px 0;
                        }
                    }
                }
            }

            dl {
                margin-top: 16px;
                margin-bottom: 16px;
            }

            dt {
                @include font(21px, 25px, 700, $almost-black);
                margin-bottom: 16px;
                margin-top: 8px;

                @include less-than('sm') {
                    @include font(18px, 28px, 700, $almost-black);
                }
            }

            dd {
                @include font(18px, 28px, 400, $font);

                @include less-than('sm') {
                    @include font(16px, 28px, 400, $font);
                }
            }

            & .paramname,
            & .paramname * {
                color: $green  !important;
                font-weight: 600;
                font-family: 'Source Code Pro', monospace !important;
            }

            dl.pre,
            dl.post,
            dl.invariant {
                padding-left: 16px;
                border-left: 4px solid;
                border-color: $green;
            }

            & ul,
            & ol {
                list-style-type: disc;
                list-style-position: inside;
                padding-left: 30px;
                padding-bottom: 10px;

                & li {
                    @include font(20px, 32px, 400, $font);
                    padding-bottom: 6px;

                    @include less-than('lg') {
                        @include font(18px, 28px, 400, $font);
                    }

                    @include less-than('sm') {
                        @include font(16px, 28px, 400, $font);
                    }

                    .developer-portal & {
                        @include font(18px, 28px, 400, $font);

                        @include less-than('sm') {
                            @include font(16px, 28px, 400, $font);
                        }
                    }

                    & a:first-child,
                    & p:first-child {
                        margin-top: 0;
                        display: inline;
                    }

                    & a:last-child,
                    & p:first-child {
                        margin-bottom: 0;
                    }

                    & ul {
                        margin-top: 24px;
                    }
                }
            }

            .levels {
                display: none;

                &+table {

                    & td.entry {
                        width: 40%;
                    }

                    & .arrow {
                        display: none;
                    }


                }
            }

            & .icona {
                margin-right: 8px;
                display: inline-block;
                height: 28px;
                width: 28px;
                background: $blue;
                border-radius: 6px;

                @include font(14px, 29px, 700, $white);
                text-align: center;

            }
        }
    }
}