.overflow-hidden {
    overflow: hidden !important;
}

.fh {
    height: 100% !important;
}

.fw {
    width: 100% !important;
}

.sticky {
    position: relative;

    &__item {
        position: sticky !important;
        top: 80px;
    }
}

.relative {
    position: relative !important;

}

.absolute {
    position: absolute !important;
}

.flex-column {
    display: flex;
    flex-direction: column;

    &_md {
        @include less-than('md') {
            display: flex !important;
            flex-direction: column !important;
        }
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.row-reverse {
    flex-direction: row-reverse !important;
}

.flex-center {
    @include flex(row, center);
}

.align-center {
    align-items: center !important;
}

.justify-center {
    justify-content: center !important;
}

.flex-bottom-right {
    @include flex(row, flex-start, flex-end);
}

.flex-top-right {
    @include flex(row, flex-start, flex-start);
}

.flex-bottom-left {
    @include flex(row, flex-end, flex-end);
}

.flex-top-left {
    @include flex(row, flex-end, flex-start);
}

.text-center {
    text-align: center !important;
}

.text-start {
    text-align: left !important;

    &--lg{
        @include less-than('lg'){
            text-align: left !important;
        }
    }
}

.text-end {
    text-align: right !important;
}

.gap {
    &_20 {
        gap: 20px !important;
    }

    &_40 {
        gap: 40px !important;
    }

    &_36 {
        gap: 36px !important;
    }

    &_0 {
        gap: 0 !important;

        &-lg {
            @include less-than('lg') {
                gap: 0 !important;
            }
        }

        &-md {
            @include less-than('md') {
                gap: 0 !important;
            }
        }

        &-sm {
            @include less-than('sm') {
                gap: 0 !important;
            }
        }
    }
}

.d-none {
    display: none !important;
}


.mb {
    &-0 {
        margin-bottom: 0 !important;
    }

    &-8 {
        margin-bottom: 8px !important;
    }

    &-16 {
        margin-bottom: 16px !important;
    }

    &-24 {
        margin-bottom: 24px !important;
    }

    &-32 {
        margin-bottom: 32px !important;
    }

    &-40 {
        margin-bottom: 40px !important;
    }

    &-48 {
        margin-bottom: 48px !important;
    }

    &-64 {
        margin-bottom: 64px !important;
    }

    &-80 {
        margin-bottom: 80px !important;
    }

    &-auto {
        margin-bottom: auto !important;
    }
}

.mt {

    &-16 {
        margin-top: 16px !important;
    }

    &-24 {
        margin-top: 24px !important;
    }

    &-32 {
        margin-top: 32px !important;
    }

    &-40 {
        margin-top: 40px !important;
    }

    &-48 {
        margin-top: 48px !important;
    }

    &-56 {
        margin-top: 56px !important;
    }

    &-64 {
        margin-top: 64px !important;
    }

    &-80 {
        margin-top: 80px !important;
    }

    &-auto {
        margin-top: auto !important;
    }
}

.mr {

    &-0 {
        margin-right: 0 !important;
    }

    &-16 {
        margin-right: 16px !important;
    }

    &-32 {
        margin-right: 32px !important;
    }

    &-auto {
        margin-right: auto !important;
    }
}

.ml {
    &-0 {
        margin-left: 0 !important;
    }

    &-24 {
        margin-left: 24px !important;
    }

    &-auto {
        margin-left: auto !important;
    }
}

.pb {
    &-0 {
        padding-bottom: 0 !important;
    }

    &-32 {
        padding-bottom: 32px !important;
    }
}

.pl {
    &-0 {
        padding-left: 0 !important;
    }

    &-8 {
        padding-left: 8px !important;
    }

    &-16 {
        padding-left: 16px !important;
    }
}

.pr {
    &-0 {
        padding-right: 0 !important;
    }

    &-16 {
        padding-right: 16px !important;
    }
}

.pb {
    &-0 {
        padding-bottom: 0 !important;
    }

    &-16 {
        padding-bottom: 16px !important;
    }
}

.pt {
    &-0 {
        padding-top: 0 !important;
    }

    &-16 {
        padding-top: 16px !important;
    }

    &-96 {
        padding-top: 96px !important;
    }
}

.p{
    &-16{
        padding: 16px !important;
    }
}

.color-gray-40 {
    color: $light-gray-40  !important
}

.hide {
    &-xxxl {
        @include less-than('xxxl') {
            display: none !important;
        }
    }

    &-xxl {
        @include less-than('xxl') {
            display: none !important;
        }
    }

    &-xl {
        @include less-than('xl') {
            display: none !important;
        }
    }
}

.show {
    &-sm {
        display: none !important;

        @include less-than('sm') {
            display: block !important;
        }
    }

    &-md {
        display: none !important;

        @include less-than('md') {
            display: block !important;
        }
    }

    &-lg {
        display: none !important;

        @include less-than('lg') {
            display: block !important;
        }
    }

    &-xl {
        display: none !important;

        @include less-than('xl') {
            display: block !important;
        }
    }
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.top {
    &-0 {
        top: 0 !important
    }

    &-16 {
        top: 16px !important
    }
}

.rounded {
    @include rounded();
}

.w-100 {
    width: 100%;
    box-sizing: border-box;
}

.aspect-ratio{
    &--3-to-1{
        aspect-ratio: 3 / 1 !important;
    }
}