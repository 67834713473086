html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input {
	all: unset;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: none;
	appearance: none;
	border: 0;
	outline: 0;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

img {
	image-rendering: auto;
}

#hs-eu-cookie-confirmation,
#hs-eu-policy-wording,
#hs-eu-cookie-disclaimer {
	font-family: 'Inter' !important;
	color: #5a6372 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	letter-spacing: 0 !important;

	@include less-than('lg') {
		font-family: 'Inter' !important;
		color: #5a6372 !important;
		font-size: 12px !important;
		line-height: 16px !important;
		letter-spacing: 0 !important;
	}
}

#hs-eu-cookie-confirmation-inner {
	max-width: 1520px !important
}

#hs-eu-cookie-disclaimer {
	margin: 0 !important
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
	margin-top: 16px;
	margin-right: 0 !important;
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area a,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area a#hs-eu-confirmation-button {
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;

	text-align: center !important;
	padding: 6px 24px !important;
	display: inline-block !important;
	border-radius: 100px;

	border-color: #3B83FB !important;
	color: #3B83FB !important
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area a#hs-eu-confirmation-button {
	background-color: #3B83FB !important;
	color: white !important;
}

body,
iframe,
aside {
	&::-webkit-scrollbar-thumb {
		background-color: $light  !important;
		border: 4px solid transparent !important;
		border-radius: 12px !important;
		background-clip: padding-box !important;
	}

	&::-webkit-scrollbar {
		width: 16px !important;
	}
}

.customer-portal .cookiefirst-root {
	font-family: 'Inter' !important;
}

.customer-portal .cookiefirst-root [data-cookiefirst-button="primary"] {
	color: #ffffff !important;
	background: #3b83fb !important;
	border-color: #3b83fb !important;
	border-radius: 160px !important;
}

.customer-portal .cookiefirst-root [data-cookiefirst-button="primary"]>span {
	font-weight: 600;
}

.customer-portal .cookiefirst-root [data-cookiefirst-button="primary"]:hover {
	color: #ffffff !important;
	background: #3576E2 !important;
	border-color: #3576E2 !important;
	border-radius: 160px !important;
}

.customer-portal .cookiefirst-root [data-cookiefirst-button="secondary"] {
	color: #000000 !important;
	background: #ffffff !important;
	border-color: #000000 !important;
	border-radius: 160px !important;
}

.customer-portal .cookiefirst-root [data-cookiefirst-button="secondary"]>span {
	font-weight: 600;
}

.customer-portal .cookiefirst-root [data-cookiefirst-button="secondary"]:hover {
	color: #ffffff !important;
	background: #3576E2 !important;
	border-color: #3576E2 !important;
	border-radius: 160px !important;
}

.customer-portal #cookiefirst-root>div {
	bottom: 10px !important;
	right: 10px !important;
	left: auto !important;
	max-width: 800px !important;
	width: calc(100% - 20px) !important;
	background: transparent !important;
}

.customer-portal .cookiefirst-root [data-cookiefirst-widget] {
	border-radius: 12px !important;
	color: white !important;
	background: black !important;
}

.calendly-inline-widget iframe div[data-id="branding"] {
	display: none !important;
}