.tabs {
    &__toggles {
        width: 100%;
        overflow-x: auto;
        padding-bottom: 24px;
        @include flex(row, flex-start);

        @include less-than('md') {
            padding-bottom: 16px;
        }

        margin-bottom: 56px;
        transition: $transition;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background: $tabs;
            bottom: 1px;
            left: 0;
            z-index: 1;

            @include less-than('sm') {
                display: none;
            }
        }

        & .toggle {
            @include font(18px, 22px, 600, $font);

            position: relative;
            display: inline-block;

            margin-right: 32px;
            margin-top: 8px;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                color: $blue;
            }

            &.toggle_active {
                color: $black;
                pointer-events: none;

                &::after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background: $blue;
                    bottom: -24px;
                    left: 0;
                    z-index: 2;

                    @include less-than('md') {
                        bottom: -16px;
                    }

                    @include less-than('sm') {
                        bottom: -6px;
                    }
                }
            }
        }
    }

    & .tab {

        &__title {
            margin: 8px 0 24px;
        }

        &__description {
            @include font(18px, 28px, 300, $font);

            &+a {
                display: inline-block;
                margin-top: 58px;
            }
        }

        &:hover {

            & .tab__title a {
                color: $blue;
            }

        }


    }

    &#faqs-tabs {
        margin-top: 10px;
        padding-bottom: 56px;
        border-bottom: 1px solid $tabs;
    }

    & #tab-description {
        width: calc(100% / 3);
        position: relative;

        &>.tab {
            top: 100px;
        }

        @include less-than('md') {
            margin-bottom: 30px;
        }
    }
}

.shortcuts {
    margin-top: 56px;
    padding: 56px 0;
    width: 100%;

    @include less-than('lg') {
        padding-top: 31px;
        padding-bottom: 27px;
    }

    @include less-than('md') {
        padding-top: 0;
        padding-bottom: 0;
    }

    background-color: $gray;

    & .shortcut {
        flex-direction: column;

        & .title {
            margin-top: 35px;
            margin-bottom: 16px;
            transition: $transition;
        }

        & .subtitle {
            @include font(18px, 28px, 400, $font);
            max-width: 90%;
            
        }

    }

    & a.shortcut:hover {
        & .title {
            color: $blue
        }
    }

    &_customer {
        margin-top: 0;
        margin-bottom: 40px;
        padding: 0;
        background-color: $white;

        & .container {

            padding-bottom: 0;

            & .loop {
                border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
                padding-bottom: 80px;

                @include less-than('lg'){
                    padding-bottom: 40px;
                }
            }
        }

        & .shortcut {
            text-align: center;
            align-items: center;
            justify-content: center;

            & .svg {
                margin-left: auto;
                margin-right: auto;
            }

            & .title {
                margin-top: 16px;
                margin-bottom: 16px;

                line-height: 133%;
            }

            & .subtitle{
                margin: auto;
            }
        }

    }
}

.quickaccess {
    &__columns.columns {
        gap: 50px;
    }

    &__card {
        @include flex(column, flex-start);
        padding: 60px 40px 40px 40px;
        @include rounded();
        background-image: url(#{$path}/assets/img/quickaccess-card-bg.jpg);

        @include less-than('sm') {
            padding: 32px;
        }

        &>.title {
            margin-bottom: 24px;

        }

        &>.subtitle {
            color: rgba($color: $white, $alpha: 0.7);
        }

        & .searchbar {
            width: 100%;
            margin-top: auto;

            @include less-than('lg') {
                margin-top: 32px;
            }

            &__input {
                border-color: rgba($color: $white, $alpha: 0.5);

                &>div:last-child {
                    border-color: rgba($color: $white, $alpha: 0.5);

                    &>label {
                        color: rgba($color: $white, $alpha: 0.5);
                    }

                    &>input {
                        color: $white;

                        &::placeholder {
                            color: rgba($color: $white, $alpha: 0.5);
                        }
                    }

                    &>.svg {
                        & path {
                            stroke: rgba($white, 0.5);
                            transition: stroke 0.3s ease-in-out;
                        }

                        &:hover{
                            & path {
                                stroke: rgba($white, 1.0);
                            }
                        }
                    }
                }
            }

            &_focused label {
                color: rgba($color: $white, $alpha: 0.5) !important;
            }

            &__button .svg path {
                fill: $white;
            }
        }

    }

    &__item {
        padding: 20px 0;
        margin-top: 3px;
        border-bottom: 1px solid $footer;

        & .title {
            margin-bottom: 16px;

        }

        & p {
            @include font(18px, 28px, 300, $font);
            max-width: calc(100% / 3 * 2);

            @include less-than('md') {
                max-width: none;
            }
        }

        & .readmore {
            display: inline-block;
            margin-top: 27px;
        }
    }

    &__items>div {
        &:first-child {
            padding-top: 0;
            margin-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

.contactpage {

    @include flex(row, space-between, flex-start);
    gap: 40px;

    @include less-than('xl') {
        @include flex(column-reverse, flex-start);
        gap: 0;
    }

    & form {
        margin-left: auto;
        margin-right: auto;
        max-width: 493px;
        min-width: 360px;

        @include less-than('xl') {
            width: 100%;
            max-width: none;
            min-width: 0;

            margin-top: 0;
        }
    }

    &__info {
        flex: 0 0 667px;
        margin-right: auto;

        @include less-than('xxl') {
            flex-shrink: 1;
        }

        @include less-than('xl') {
            width: 100%;
        }


        & .columns {
            margin-bottom: 56px;

            @include less-than('xxl') {
                flex-wrap: wrap;

                & .column {
                    width: auto;
                }
            }

            @include less-than('xl') {

                @include flex(row, flex-start);

                & .column {
                    width: auto !important;
                }
            }

            @include less-than('md') {
                & .column {
                    width: 100% !important;
                }
            }
        }

        & .title {
            margin-bottom: 24px;

            @include font(28px, 40px, 600, $black);

            &:last-child {
                display: none;

                @include less-than('xl') {
                    display: block;
                }
            }
        }

        & .subtitle {
            margin-bottom: 8px;
            font-weight: 600;
            color: $black;
        }
    }

}

.supportpage {

    @include flex(row, space-between, flex-start);
    gap: 40px;

    @include less-than('xl') {
        @include flex(column-reverse, flex-start);
        gap: 0;
    }

    &__form {
        margin-left: 0;
        margin-right: auto;
        max-width: 493px;
        min-width: 360px;

        @include less-than('xl') {
            width: 100%;

            max-width: none;
            min-width: 0;

            margin-top: 40px;
            padding-top: 32px;
        }

        & > .title{
            margin-bottom: 8px;
    
            line-height: 133%;
        }
    }


    &__faq {
        max-width: 886px;

        @include less-than('xl') {
            width: 100%;

            max-width: none;
            min-width: 0;   
        }

        & > .title{
            margin-bottom: 8px;
    
            line-height: 133%;
        }

        & .card_faq {

            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            border-top: 0.5px solid rgba(0, 0, 0, 0.2);


            &:first-child {
                border-top: 0 !important;
            }

            &:last-child {
                border-bottom: 0 !important;
            }

            &>.title {
                @include font(20px, 32px, 400, $font);

                &:hover {
                    color: $blue  !important;
                }
            }

            & .text,
            & .text p {
                @include font(18px, 30px, 400, $font);
            }

            &.faq_active {
                &>.title {
                    color: $black;
                }
            }

        }
    }


    @include less-than('xl') {
        &>.columns {
            flex-direction: column-reverse;
        }

        & .column>.title,
        & .column>.subtitle {
            text-align: center;
            width: 100%;
        }
    }

}

.key-features {
    padding-top: 112px;
    padding-bottom: 132px;

    @include less-than('xl') {
        padding-top: 0;
        padding-bottom: 12px;
    }

    @include flex(row, space-between, center);

    @include less-than('lg') {
        @include flex(column, flex-start);
    }

    &>.title {
        padding-right: 20px;
        flex: 1 1 30%;
        color: $black;

        @include less-than('lg') {
            margin-bottom: 56px;
        }

    }

    &__sidebar {
        flex: 1 1 40%;

        & .title {
            @include font(48px, 56px, 600, $black);
            margin-bottom: 56px;

            @include less-than('lg') {
                display: none;
            }

        }
    }

    &__item {

        @include flex(row, space-between, center);

        @include less-than('md') {
            @include flex(column, flex-start);
        }


        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }

        &>span {
            @include font(64px, 72px, 500);
            min-width: 80px;

            &.cyan {
                letter-spacing: 0.04em;
            }
        }

        &>p {
            @include font(20px, 32px, 400, $font);
            padding-left: 16px;

            @include less-than('xl') {
                @include font(16px, 28px, 400, $font);
            }

            @include less-than('md') {
                padding-left: 0;
                margin-top: 8px;
            }

            &>b {
                color: $almost-black;
                font-weight: 500;
            }
        }
    }
}

.influx {
    @include less-than('sm') {
        margin-bottom: 8px;
    }

    & .columns {
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 20px;

        top: 24px;

        @include less-than('lg') {
            flex-direction: column-reverse;
            gap: 0;
            top: 0;

            background-color: #ffffff;
            padding-top: 24px;
            padding-bottom: 0;
        }

        @include less-than('sm') {
            padding-top: 8px;
            gap: 0;
        }

        &>.column:first-child {
            background-color: #ffffff;
            padding-right: 40px;

            @include less-than('lg') {
                padding-right: 0;
                margin-bottom: 56px;
                margin-top: 0;
            }
        }

        &>.column:last-child {

            @include less-than('lg') {
                margin-bottom: 24px;
                margin-top: 0;
            }
        }
    }

    &__block {
        & .title {
            margin-bottom: 24px;

            @include less-than('lg') {
                @include font(40px, 56px, 700, $almost-black);
            }

            @include less-than('sm') {
                margin-bottom: 24px;
                @include font(32px, 40px, 700, $almost-black);
            }
        }

        & .description {
            margin-top: 24px;
            margin-bottom: 16px;
            padding-right: 20px;

            @include less-than('sm') {
                margin-top: 24px;
                margin-bottom: 24px;
            }
        }

        & .btn_coming-soon {
            margin-top: auto;
            color: $light-gray-30;
        }
    }

    &__img {
        position: relative;
        aspect-ratio: 1/1;

        @include less-than('lg') {
            aspect-ratio: 2/1;
        }

        @include less-than('sm') {
            aspect-ratio: 1/1;
        }

        height: 100%;

        & .img-wrapper {
            position: absolute;
            @include rounded();
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @include less-than('md') {
        & .sticky__item {
            position: relative !important;
            top: 0;
        }
    }

    &_sm {

        & .column {
            margin-top: 120px;
        }

        & > .sticky__item.title{
            top:24px;
        }

        & .influx__block {
            & .title {
                margin-bottom: 4px;

                @include flex(row, flex-start, center);

                & .svg {
                    margin-left: 16px;
                }
            }

            & .description {
                margin-top: 32px;
                margin-bottom: 80px;

                @include less-than('lg') {
                    margin-bottom: 0;
                }
            }

            & .p4 {
                margin-top: 32px;
            }
        }

    }

}

.quotes {
    &.loop {
        row-gap: 32px;
        column-gap: 80px;

        @include less-than('xl') {
            row-gap: 32px;
            column-gap: 64px;
        }

        @include less-than('lg') {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__meta {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;

        @include less-than('lg') {
            margin-bottom: 8px;
        }

        gap: 16px;

        & a {
            @include font(16px, 24px, 400, $font);
        }
    }
}

.newsletter {
    & .columns {
        align-items: center;
    }

    & .input-wrapper {
        max-width: 550px;
        margin-left: auto;

        @include less-than('lg') {
            max-width: none;
            margin-left: 0;
        }

        &.validator_active{
            margin-left: 0;
            margin-right: auto;
        }
    }

    & .title {
        margin-bottom: 16px;

        @include less-than('sm') {
            @include font(40px, 48px, 700, $black);
        }
    }
}


.vacancies {
    &__item {
        margin-bottom: 16px;

        & .accordeon__header {
            border: 0;
            padding: 36px 32px;

            @include less-than('sm') {
                padding: 18px 16px;
            }

            background-color: $devicebar;
            position: relative;
            cursor: pointer;

            & .subtitle {
                color: $font;
                text-shadow: none;

                @include less-than('sm') {
                    display: none;
                }

                &>span {
                    padding: 0 12px;
                    border-right: 1px solid #AAABAD;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                        border-right: 0;
                    }
                }
            }

            & path {
                fill: $font;
                transition: fill 0.3s ease-in-out;
            }

            &:hover{
                & path {
                    fill: $blue;
                }
            }
        }

        & .accordeon__body {
            border: 0;
            padding: 0 32px;

            @include less-than('sm') {
                padding: 0px 16px;
            }

            background-color: $devicebar;

            // & p,
            // & li {
            //     @include font(20px, 32px, 400, $font);
            // }



            & .btn_underline {
                color: initial;
                margin-top: 64px;
            }
        }

        &.accordeon_active {
            & .accordeon__header {
                padding-bottom: 16px;

                &::after {
                    content: '';
                    position: absolute;
                    width: calc(100% - 64px);
                    background: radial-gradient(136.38% 136.38% at 5.03% 4.1%, #122337 0%, #01020A 100%);
                    height: 0.5px;
                    bottom: 0;
                }
            }

            & .accordeon__body {
                padding: 20px 32px 32px 32px;

                @include less-than('sm') {
                    padding: 10px 16px;
                }
            }


        }
    }
}

.use-case {
    &__banner {
        margin-bottom: 50px;
        background-size: cover;

        @include less-than('xxxl') {
            padding: 0;
        }

        @include less-than('lg') {
            background: $black  !important;
        }

        &>.container {
            aspect-ratio: 2/1;
            padding-top: 24px;
            padding-bottom: 24px;
            @include flex(row, space-between, flex-start);

            @include less-than('xxxl') {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            @include less-than('xl') {
                padding-top: 32px;
                padding-bottom: 32px;
            }
        }

        & .right-part {
            margin-left: auto;
        }


        & .title {
            color: white !important;
            margin-bottom: 16px;
            max-width: 365px;
        }

        & hr {
            background: linear-gradient(90deg, #2878FF 0%, rgba(40, 120, 255, 0) 105.17%);
            width: 200px;
            height: 2px;
            border: 0;
            margin: 0;
        }

        & .description {
            color: rgba($color: #C9C9C9, $alpha: 1);
            margin-top: 24px;

            max-width: 365px;
            margin-bottom: 80px;

            @include less-than('xxxl') {
                margin-top: 16px;
                margin-bottom: 20px;
                max-width: calc(100% / 3 * 2);
            }

            @include less-than('xl') {
                margin-top: 16px;
                margin-bottom: 24px;
                max-width: 80%;
            }

            @include less-than('md') {
                margin-bottom: 20px;
                max-width: 100%;

                @include font(16px, 28px, 400, #C9C9C9)
            }

            &>b {
                color: rgba($color: #ffffff, $alpha: 0.85) !important;
                font-weight: 600 !important;

            }
        }
    }

    &__content {
        &>.title {
            margin-bottom: 32px;
            text-align: center;
        }

        &>p {
            margin: 48px 0;

            @include less-than('lg') {
                margin: 36px 0;
            }

            @include less-than('sm') {
                margin: 24px 0;
            }
        }

        & .section-title+p {
            margin-top: 24px;
        }
    }

}

.list-with-pluses {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;

    & ul li {
        margin-bottom: 24px;
        padding-left: 32px;
        position: relative;
        text-align: left;

        @include less-than('xxl') {
            margin-bottom: 16px;
        }

        @include less-than('xl') {
            margin-bottom: 12px;
        }

        @include less-than('lg') {
            margin-bottom: 24px;
        }

        @include less-than('sm') {
            margin-bottom: 12px;
        }

        b {
            font-weight: 600;
            color: $black;
        }


        &::before {
            content: url(#{$path}/assets/icons/plus-li.svg);
            height: 24px !important;
            width: 24px !important;
            left: 0;

            position: absolute;
            top: 4px;
            // transform: translateY(-50%);
            transition: $transition;

            @include less-than('sm') {
                & {
                    top: 1px;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

}

.text-about-partner {
    @include flex(row, space-between, center);

    @include less-than('sm') {
        @include flex(column, flex-start);
    }


    & .text {

        max-width: 1100px;

        & .title {
            margin-bottom: 24px;
            text-align: left;

            @include less-than('sm') {
                @include font(20px, 32px, 600, $black);
            }
        }

        & p {
            margin: 0;
        }
    }

    & .partner {
        @include flex(column, space-between, flex-end);
        width: 150px;
        margin-left: 16px;

        & .title {
            margin-top: 24px;
            text-align: right;
        }

        @include less-than('sm') {
            width: auto;
            text-align: center;
            margin: auto;
            align-items: center;
            margin-top: 24px;
        }
    }


}

.two-images {
    @include flex(row, space-between, stretch);
    gap: 20px;

    @include less-than('sm') {
        @include flex(column, flex-start, stretch);
    }
}

.text-bannered {
    background-color: $devicebar;
    @include rounded();

    margin-bottom: 20px;
    aspect-ratio: 2 / 1;

    box-sizing: border-box;

    @include flex(column, flex-start, center);
    text-align: center;
    padding: 64px 80px;

    @include less-than('xl') {
        padding: 32px;
    }

    @include less-than('lg') {
        padding: 40px;
        aspect-ratio: auto;
    }

    @include less-than('md') {
        padding: 24px;
    }

    & .subtitle {
        margin-bottom: 8px;

        @include less-than('xl') {
            margin-bottom: 0;
        }
    }

    & .title {
        margin-bottom: 32px;
        letter-spacing: -0.02em;

        @include less-than('xl') {
            margin-bottom: 24px;
            @include font(40px, 48px, 700, $black);
        }

        @include less-than('sm') {
            @include font(32px, 40px, 700, $black);
            margin-bottom: 16px;
        }
    }

    & .description {
        margin-bottom: 56px;
        width: 80%;

        @include less-than('xxl') {
            width: 100%;
        }

        @include less-than('xl') {
            margin-bottom: 24px;
        }

        @include less-than('lg') {
            @include font(18px, 28px, 400, $font);
        }

        @include less-than('md') {
            margin-bottom: 16px;
        }
    }

    & .content {
        font-weight: 600;
        margin-top: auto;
        margin-bottom: auto;

        @include less-than('xl') {
            @include font(20px, 32px, 600, $black)
        }

        @include less-than('sm') {
            @include font(16px, 28px, 600, $black)
        }
    }

    & .list-with-pluses {
        margin-top: 8px;

        & ul li {
            &>b {
                font-weight: 600 !important
            }
        }
    }

    & .flex-row {
        margin-top: auto;
        margin-bottom: auto;
        @include flex(row, center, center);
        gap: 80px;

        width: calc(100% / 3 * 2);

        @include less-than('xxl') {
            width: 100%;
            gap: 64px;
        }

        @include less-than('xl') {
            gap: 32px;
        }

        &>div {
            height: auto;
            width: 100%;

            &>img {
                height: auto;
                width: 100%;
                max-width: min-content;
                object-fit: contain;
            }
        }
    }
}

.infographics {
    @include flex(row, space-between, flex-start);

    &--reversed {
        flex-direction: row-reverse;
    }

    @include less-than('lg') {
        &--reversed {
            @include flex(column, flex-start, center);
        }

        @include flex(column, flex-start, center);
    }

    gap: 20px;

    & .content {
        flex: 0 1 60%;
        padding-right: 20px;
        padding-left: 0;

        &>.title {
            max-width: 90%;
            margin-bottom: 32px;

            @include less-than('xl') {
                max-width: none;
            }

            @include less-than('lg') {
                margin-bottom: 16px;
            }
        }

        &>a {
            margin-top: 96px;
        }
    }

    &--reversed .content {
        padding-right: 0;
        padding-left: 20px;

        @include less-than('lg') {
            padding-left: 0;
        }
    }

    & .sidebar {
        flex: 0 1 30%;
        @include flex(column, flex-start, center);

        @include less-than('lg') {
            padding-top: 20px;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }

        &>.title {
            margin-bottom: 32px;

            @include font(40px, 56px, 600, $black);
            text-align: center;

            @include less-than('xl') {
                @include font(28px, 130%, 600, $black);
                text-align: left;
            }

            @include less-than('md'){
                @include font(24px, 150%, 600, $black);
            }

            &--small{
                @include font(32px, 48px, 600, $black);

                @include less-than('xxl'){
                    @include font(28px, 130%, 600, $black);
                }

                @include less-than('lg'){
                    @include font(24px, 150%, 600, $black);
                }
            }
        }


    }

    &_equal {
        & .content {
            flex-basis: 50%;

            @include less-than('xl') {
                flex-basis: calc(100% / 3 * 2);
            }

            @include less-than('lg') {
                flex-basis: 100%;
            }
        }

        & .sidebar {
            flex-basis: 50%;

            @include less-than('xl') {
                flex-basis: (100% / 3);

                & img {
                    width: 100%
                }
            }

            @include less-than('lg') {
                flex-basis: 100%;
            }
        }
    }

    &__icons {
        width: 100%;
        @include flex(row, space-between);
        row-gap: 32px;
        column-gap: 20px;
        flex-wrap: wrap;
    }

    &__item {
        width: calc(50% - 10px);
        text-align: center;
        @include flex(column, center);

        @include less-than('lg'){
            width: calc(33% - 20px);
            align-items: flex-start;
        }

        @include less-than('md'){
            width: calc(50% - 10px);
        }

        & .svg {
            margin-bottom: 16px;
        }

        & .title {
            @include font(20px, 28px, 500, $black);
            text-align: center;

            @include less-than('xl'){
                @include font(18px, 26px, 500, $black);
            }

            @include less-than('lg'){
                text-align: left;
            }

            @include less-than('md'){
                line-height: 24px;
            }
        }
    }


}

.mediakit {

    &-logos {
        width: 100%;

        @include flex(row, flex-start, stretch);

        @include less-than('xl') {
            @include flex(column, flex-start, stretch);
        }

        &>div {
            flex: 1;
        }

        & .container {
            max-width: calc(1520px / 2 + 20px * 2);
        }

        &--titles {
            & .container {
                padding-top: 32px;
                padding-bottom: 40px;
            }

            & .loop>div:first-child {
                & .container {
                    margin-right: 0;
                }
            }

            & .loop>div:last-child {
                & .container {
                    margin-left: 0;
                }
            }

            @include less-than('xl') {
                display: none;
            }
        }

        & .loop {
            &>div {
                width: auto;
            }
        }

        &__light {
            background: $devicebar;

            & .container {
                margin-right: auto;
            }

            & .mediakit-logos__item {
                & path {
                    fill: $black;
                }
            }

        }

        &__dark {
            background: $black;

            & .container {
                margin-left: auto;
            }

            & path {
                fill: $white;
            }


        }

        &__item {
            @include flex(row, flex-start, center);
            padding: 10px auto;
            margin-top: 64px;
            margin-bottom: 64px;

            & img {
                width: auto;
                max-height: 75px;

                @include less-than('md') {
                    max-width: 250px;
                    height: auto;
                }
            }

            & .svg {
                cursor: pointer;
                padding-left: 80px;

                @include less-than('xxl') {
                    padding-left: 24px;
                }
            }

            @include less-than('xxl') {
                @include flex(row, space-between, center);
            }
        }

    }
}

.api-download-cta {
    background: $black;
    margin-top: 256px;
    padding-bottom: 0;
    padding-top: 100px;

    @include less-than('lg') {
        margin-top: 0;
        padding-top: 32px;
    }

    & .container {
        padding-bottom: 0;
    }

    & p,
    & a,
    & b{
        color: $white  !important;

    }

    & .column:first-child {
        @include flex(column, flex-start);
        padding-bottom: 64px;
    }

    & .title {
        color: $white !important;
        margin-bottom: 64px;

        @include less-than('md') {
            margin-bottom: 16px;
        }
    }

    & .flex-row {
        margin-bottom: 16px;

        @include less-than('md') {
            flex-wrap: wrap;
        }

        & .toggle {
            margin-right: 32px;
            color: $white;
            cursor: pointer;
            border-bottom: 2px solid transparent;

            @include less-than('md') {
                margin-top: 16px;
            }

            &_active {
                border-bottom: 2px solid $blue;
            }
        }
    }

    & .description {
        margin-top: 32px;
    }

    & .description+a {
        margin-top: 200px;
        margin-bottom: 48px;

        @include less-than('lg') {
            margin-top: 64px;
        }

        &+p {
            color: #C9C9C9 !important
        }
    }

    & .column:last-child {
        position: relative;
    }

    & img {
        position: absolute;
        bottom: 0;
        max-width: 750px;

        @include less-than('xxxl') {
            width: 100%;
        }

        @include less-than('lg') {
            position: relative;
        }
    }

}

.section-cta {
    @include flex(column, space-between, center);
    @include rounded();
    
    background: $gradient-startscreen;
    padding: 140px 16px;

    & .section-heading {
        margin-bottom: 140px;

        & .title,
        & .subtitle {
            color: $white;
        }
    }

    & a.readmore {
        color: $white;

        &::after {
            content: url(#{$path}/assets/icons/arrow-white.svg);
        }
    }

}

.post-type-archive-event .centered-toggles {
    margin-bottom: 0;
}

.post-type-archive-event .sidebar {
    padding-top: 64px;

    @include less-than('lg') {
        padding-top: 16px;
    }
}

#events-loop {
    @include flex(row, space-between, stretch);
    flex-wrap: wrap;

    & .sidebar {
        min-width: 385px;
        max-width: 385px;
    }

    & .sidebar+.content {
        margin-left: 0;
    }

    & .content {
        width: calc(100% - (385px + 20px));
    }

    @include less-than('xl') {
        & .sidebar {
            min-width: 285px;
            max-width: 285px;
        }

        & .content {
            width: calc(100% - (285px + 20px));
        }
    }

    @include less-than('lg') {
        & .sidebar {
            min-width: 100%;
            max-width: 100%;
        }

        & .content {
            width: 100%;
            margin-top: 0;
        }
    }

    & .sidebar {
        &__menu {
            position: sticky;
            top: 80px;

            & ul li {
                margin-bottom: 0;
                padding: 8px 20px 8px 12px;
                border-left: 2px solid $gray;
                transition: $transition;

                @include font(20px, 32px, 600, $font);

                & a {
                    display: block;
                    @include font(20px, 32px, 600, $font);

                    &:hover {
                        cursor: pointer;
                        color: $black;
                    }
                }


                @include less-than('md') {
                    padding: 8px 12px 8px 12px;

                    @include font(18px, 28px, 600, $font);

                    & a {
                        @include font(18px, 28px, 600, $font);
                    }
                }

                &.active {
                    border-color: $blue;
                    color: $black;
                    font-weight: 600;

                    & a {
                        color: $black;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    & .content {
        & .loop {
            row-gap: 0;

            & .card {
                padding-top: 64px;

                @include less-than('lg') {
                    padding-top: 32px;
                }

                &:first-child {
                    padding-top: 64px;

                    @include less-than('lg') {
                        padding-top: 32px;
                    }
                }
            }
        }
    }
}



hr.before-section-heading {
    width: 100%;
    height: 1.5px;
    background-color: $black;
    margin-top: 80px;
    margin-bottom: 80px;
    border: 0;
    outline: 0;
}

.fullscreen-section {
    position: relative;
    aspect-ratio: 2/1;
    padding-top: 124px;
    padding-bottom: 124px;

    @include less-than('lg'){
        padding: 0;
    }

    &--reversed {

        & .title,
        & .text {
            margin-left: auto;
        }
    }

    &>.container {
        z-index: 2;

        @include less-than('lg'){
            padding-top: 56px;
        }

        @include less-than('md'){
            padding-top: 32px;
        }
    }

    & .title {
        z-index: 2;
        max-width: 750px;
        margin-bottom: 48px;

        @include less-than('lg'){
            margin-bottom: 24px;
        }
        @include less-than('sm'){
            margin-bottom: 16px;
        }

        color: $white  !important;

        @include less-than('lg'){
            width: 100%;
            max-width: none;
        }
    }

    & .text {
        z-index: 2;
        max-width: 750px;

        color: $white;
        font-weight: 400;

        & b {
            color: $white;
            font-weight: 600;
        }

        @include less-than('lg'){
            width: auto;
            max-width: none;
        }
    }

    & img {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        height: auto;
        width: 50%;

        @include less-than('lg'){
            position: relative;
            width: 100%;
            max-width: 100%;
        }
        
    }

    &--man-with-gloves{
        & img{
            width: 70%;
            max-width: 1100px;
        }

        & .title{
            @include less-than('lg'){
                @include font(56px, 72px, 600, $white);
            }
            @include less-than('md'){
                @include font(28px, 40px, 600, $white);
            }
        }

        & .text{
            @include less-than('xxxl'){
                width: calc(30% + 40px);
            }
        }

    }

    &--academics{
        & img{
            max-width: 800px;
            width: 50%;
        }

        & .text{
            @include less-than('xxxl'){
                width: calc(40% + 40px);
            }
        }
    }

    &--hand-with-apple {
        & img {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            max-width: 922px;
            width: 50%;

            @include less-than('lg'){
                transform: none;
                top: 0;
            }
        }

        & .text,
        & .title{
            @include less-than('xxxl'){
                width: calc(50% + 40px);
            }
        }

        & .title{
            @include less-than('lg'){
                @include font(64px, 80px, 600, $white);
            }
            @include less-than('md'){
                @include font(28px, 40px, 600, $white);
            }
        }
    }
}

.software-quickaccess {
    @include flex(row, space-between, stretch);
    gap: 20px;

    @include less-than('lg'){
        @include flex(column, flex-start);
    }

    &__card {
        @include flex(column, flex-start);
        box-sizing: border-box;


        background-color: $devicebar;
        @include rounded();

        &--horizontal {
            flex: 1 1 calc(60% - 10px);

            padding: 64px 80px;
            padding-bottom: 0;

            @include less-than('xxl') {
                padding: 48px;
                padding-bottom: 0;
            }

            @include less-than('xl') {
                padding: 32px;
                padding-bottom: 0;
            }
    
            @include less-than('lg') {
                padding: 24px;
                padding-bottom: 0;
            }

        }

        &--vertical {
            flex: 1 1 calc(40% - 10px);

            padding: 56px 80px;
            padding-bottom: 48px;

            @include less-than('xxl') {
                padding: 48px;
            }

            @include less-than('xl') {
                padding: 32px;
            }
    
            @include less-than('lg') {
                padding: 24px;
            }
        }

        & .svg {
            margin-bottom: 16px;
        }

        & .title {
            margin-bottom: 24px;
        }

        & .description {
            margin-bottom: 24px;
        }

        & img {
            margin-top: auto;
            width: 100%;
            height: auto;

            @include less-than('lg'){
                margin-top: 24px;
            }
        }
    }
}

#calendly-widget {
    width: 100%;
    height: 100%;
    min-height: 400px;
    overflow: hidden;
}

.badges{
    @include flex(row, space-between, flex-start);

    @include less-than('lg'){
        @include flex(column);
    }

    &__item{
        width: calc(50% - 10px);

        @include less-than('lg'){
            width: 100%;
            margin-bottom: 40px;
        }

        & p{
            margin-bottom: 40px;
            padding: 0 32px;

            @include font(18px, 28px, 400, $font);

            @include less-than('lg') {
                @include font(16px, 28px, 400, $font);
            }

            @include less-than('sm') {
                @include font(14px, 24px, 400, $font);
            }

        }

        & a{
            padding: 0 32px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }
}

.badge{
    @include flex(row, flex-start, center);
    padding: 32px;
    background-color: $gray;
    @include rounded();
    margin-bottom: 40px;

    @include less-than('lg') {
        & .svg{
            width: 64px !important;
            height: 64px!important;
        }
    }

    & span{
        padding-left: 40px;

        @include less-than('lg') {
            padding-left: 16px;
        }

        // @include font(38px, 46px, 700, $black);
    }
}