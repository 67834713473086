.header {
    z-index: 999;
    width: 100%;

    box-sizing: border-box;

    background-color: $white;

    &__top {
        width: 100%;
        background: white;

        &>.container {
            @include flex(row, space-between, center);
            
            padding-top: 16px;
            padding-bottom: 16px;
            z-index: 1000;
            width: inherit;

            @include less-than('lg') {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }



        &_fixed {
            position: fixed;
            left: 0;
            top: 0;
            transition: all 0.4s ease-in-out;
        }

        &_down {
            top: 0;
            box-shadow: inset 0px -2px 0px #F9FAFB;
        }

        &_up {
            top: -64px;

        }
    }

    &__logo {
        position: relative;
        width: 163px;

        & img{
            content: url("#{$path}/assets/img/logo.svg");
        }
        

        & > a{
            align-items: flex-end;

            & > span{
                padding-left: 2px;
                @include font(16px, 19px, 400, $font);
            }
        }

        
    }

    &__menu {
        @include less-than('xl') {
            display: none;
        }
    }

    &__submenu {
        margin-top: 3px;

        @include less-than('xl') {
            display: none;
        }
    }

    &__actions {
        @include flex(row, flex-end, center);
        flex-shrink: 0;

        & .link {
            @include font(16px, 24px, 400, $footer)
        }

        & a.link {
            margin-right: 16px;
            padding: 4px 8px;
        }

        @include less-than('xl') {
            margin-left: auto;

            & a {
                margin-right: 40px;
            }
        }

        @include less-than('sm') {
            display: none;
        }

    }

    &__mobile {
        display: none;
        cursor: pointer;

        @include less-than('xl') {
            display: block;
        }

        &.burger {
            &>.svg:last-child {
                display: none;
            }
        }

        &.cross {
            &>.svg:first-child {
                display: none;
            }

            & path {
                stroke: $font !important;
            }
        }
    }

}

body.overflow-hidden .header__top{
    @include less-than('xl'){
        background: white !important;

        &_down{
            box-shadow: none;
        }

        & img{
            content: url("#{$path}/assets/img/logo.svg") !important;
        }

        .header__menu li a{
            color: $footer !important;
        }

        .header__actions .link{
            color: $footer !important;


            &:hover{
                color: $blue !important;
            }
        }

        .header__actions .btn{
            color: $white !important;
            background: $blue_main !important;
        }

        .header__mobile {
            & path[fill]{
                fill: $font !important;
            }
        
            &:hover path[fill]{
                fill: $blue_main !important;
            }
        
            & path[stroke]{
                stroke: $font !important;
                transition: stroke 0.3s ease-in-out;
            }
        
            &:hover path[stroke]{
                stroke: $blue_main !important;
            }
        }
    }
}