.footer {
    &_developer {
        background-color: $footer;
    }

    &_customer {
        & .container:first-child {
            @include flex(row, space-between, center);
            padding-bottom: 24px;
            padding-top: 24px;

            @include less-than('lg') {
                @include flex(row, flex-start, center);

                & nav{
                    margin-bottom: 0 !important;
                    width: auto;
                    text-align: left;

                    & ul{
                        justify-content: flex-start;

                        & li{
                            margin-right: 32px !important;
                            margin-left: 0 !important;
                        }
                    }
                }
            }

            @include less-than('sm') {
                @include flex(column, flex-start);
                padding-bottom: 16px;
                padding-top: 16px;
            }

        }

        & .container:last-child {
            padding-bottom: 0;
        }

        // & .footer__bottom{
        //     @include less-than('sm') {
        //         padding: 32px;
        //         padding-left: 32px !important;
        //         padding-right: 32px !important;
        //         text-align: left;
        //         justify-content: flex-start;

        //         & p{
        //             margin-bottom: 24px;
        //         }
        //     }
        // }
    }

    &__top {
        padding-bottom: 42px;
        padding-top: 108px;

        @include less-than('lg') {
            padding-top: 54px;

            & .svg {
                margin-left: auto;
                margin-right: auto;
            }
        }


    }

    &__middle {
        display: flex;
        flex-wrap: wrap;

        @include less-than('lg') {
            padding-bottom: 0;
        }
    }

    &__bottom {

        padding: 24px 20px 20px 20px;
        background: radial-gradient(110.7% 100% at 50% 100%, #0F0F0F 0%, #121112 60.95%, #0F0F0F 100%);
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include less-than('lg') {
            padding: 20px;
        }

        &>p {
            flex: 0 1 480px;

            @include font(14px, 20px, 400, rgba($footer-link, 0.8));

            @include less-than('lg') {
                flex: 1 1 100%;

                margin-bottom: 16px;
            }
        }

        &>div {

            @include less-than('lg') {
                width: 100%;
                margin-bottom: 0;

                @include flex(row-reverse, space-between, center);
            }

            @include less-than('sm') {
                @include flex(column-reverse, flex-start);
            }

            &>.menu_horizontal {
                & ul {
                    @include less-than('sm') {
                        @include flex(row, space-between, center);
                        flex-wrap: wrap;
                        margin-bottom: 16px;
                    }

                    & li {
                        margin-right: 0;

                        & a {
                            padding-top: 4px;
                            padding-bottom: 4px;
                        }

                        @include less-than('lg') {
                            margin-right: 16px;
                            margin-left: 0;


                        }

                        @include less-than('sm') {
                            margin-right: 8px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }

            }

        }
    }

    &__social {
        justify-content: flex-end;
        margin-bottom: 16px;

        & a {
            margin-left: 12px;

            &:first-child {
                margin-left: 0;
            }
        }

        @include less-than('lg') {
            justify-content: center;
            margin-bottom: 0;
        }

    }

}