.input {
    transition: $transition;
    flex-basis: 100%;

    &-group {
        width: 100%;
        margin-bottom: 16px;
        @include flex(column, flex-start, stretch);

        &__label {
            @include font(18px, 24px, 400, $black);
            margin-bottom: 8px;

            @include flex(row, space-between, center);
        }

        &__sublabel {
            margin-left: auto;
            text-align: right;
        }

        &__input {
            width: 100%;
        }
    }

    &-wrapper {
        width: 100%;
        position: relative;

        &.validator_active {
            width: calc(100% - 40px)
        }

    }

    @include font(18px, 21px, 400, $font);

    width: 100%;
    box-sizing: border-box;
    padding: 17.5px 16px;
    border-radius: 12px;
    background-color: $gray;
    border: none;
    outline: none;

}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
#bbpress-forums fieldset.bbp-form input[type=password],
#bbpress-forums fieldset.bbp-form input[type=text],
#bbpress-forums fieldset.bbp-form select {
    @include font(18px, 21px, 400, $font);

    padding: 17.5px 16px;
    border-radius: 12px;
    background-color: $gray;
    border: none;
    outline: none;
    resize: none;
    height: auto;
    min-height: 0;

    @include font(18px, 21px, 400, $font);

    &::placeholder {
        color: $light-gray-30;
    }
}

@import 'select2';

span.validator {
    display: none;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: -40px;
    width: 24px;
    height: 24px;
    content: url(#{$path}/assets/icons/validator-tick.svg);

    &_cross {
        content: url(#{$path}/assets/icons/validator-cross.svg) !important;
    }
}

input[validation] {
    &:focus {
        padding-right: 40px;
    }
}

span.visibility {
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 24px;
    width: 24px;
    height: 24px;
    content: url(#{$path}/assets/icons/eye-closed.svg);

    &_visible {
        content: url(#{$path}/assets/icons/eye-open.svg) !important;
    }
}

.confirmation-message {
    @include font(18px, 21px, 300, $green);
    margin-top: 24px;

    &_invalid {
        color: $red;
    }
}

.input_checkbox {
    &-group {
        width: 100%;
    }

    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0.5px !important;

    &:checked+label::before {
        background-image: url(#{$path}/assets/icons/checkbox.svg);
    }

    &+label {
        margin-top: 8px;
        display: inline-flex;
        align-items: center;
        user-select: none;
        @include font(18px, 21px, 400, $black);

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            flex-grow: 0;
            border: none;
            border-radius: 5px;
            background-color: $gray;
            margin-right: 24px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;

            transition: $transition;
            cursor: pointer;
        }

        &:hover::before {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        }
    }
}
.input-group{
    position: relative;
}
.customer-portal {
    & .input-group {
        margin-bottom: 24px;

        &__label {
            @include font(18px, 21px, 400, $black);
        }
    }

    .dark-theme {
        & .input-group {
            &__label {
                color: $white;
            }
        }

        & input[type="text"],
        & input[type="tel"],
        & input[type="password"],
        & input[type="email"],
        & textarea {
            background: #313131;
            color: $white;

            &::placeholder {
                color: $light-gray-30;
            }
        }

        .select2-container--default span.select2-selection--single {
            background: #313131;

            & span.select2-selection__rendered {
                color: $light-gray-30;
            }
        }

        .form__submitbar {
            &>button[type="submit"] {
                color: $white
            }

            &>p {
                color: #888788;

                &>a.link_underline {
                    color: $light-gray-30;
                    border-color: $light-gray-30;
                }
            }
        }
    }

}

input[type="password"] {
    padding-right: 60px;
}

.newsletter {
    & form {
        position: relative;

        & .input {
            padding-right: 60px;
            &::placeholder{
                color: $light-gray-30;
            }
        }

        & .input-wrapper + .svg {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            
            &.btn_disabled{
                opacity: 0.5;
                pointer-events: none;
                background: transparent
            }
        }

        & .validator_active.input-wrapper + .svg {
            right: 56px;         
        }

    }

}


.input-doublecheck {
    @include flex(row, space-between);
    gap: 10px;

    @include less-than('sm') {
        @include flex(column, flex-start);

        &>button {
            width: 100%;
        }
    }

    &>.input {
        margin-top: 0;
        min-width: 150px;

        &::placeholder {
            color: $light-gray-30;
        }

    }
}