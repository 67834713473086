.columns{
    width: 100%;
    @include flex(row, space-between, flex-start);
    position: relative;
    gap: 20px;
    

    @include less-than('lg') {
        flex-direction: column;
        gap: 30px;
        & > .column{
            width: 100% !important;
        }
    }

    &.columns_wrap-xl{

        @include less-than('xl') {
            flex-direction: column;
            gap: 30px;
            & > .column{
                width: 100%;
            }
        }
    }

    &.columns_wrap-md{
        @include less-than('lg') {
            flex-direction: row;
            gap: 20px;
            & > .column{
                width: auto
            }
        }

        @include less-than('md') {
            flex-direction: column;
            gap: 30px;
            & > .column{
                width: 100%;
            }
        }
    }

    &.columns_wrap-sm{
        @include less-than('lg') {
            flex-direction: row;
            gap: 20px;
            & > .column{
                width: auto;
            }
        }
        
        @include less-than('sm') {
            flex-direction: column;
            gap: 30px;
            & > .column{
                width: 100%;
            }
        }
    }

    &.columns_wrap-xs{
        @include less-than('lg') {
            flex-direction: row;
            gap: 20px;
            & > .column{
                width: auto;
            }
        }
        
        @include less-than('xs') {
            flex-direction: column;
            gap: 30px;
            & > .column{
                width: 100%;
            }
        }
    }

    &_stretched{
        align-items: stretch;
        
        & > .column{
            align-items: stretch;
        }
    }

    & > .column{
        @include flex(column, flex-start);

        & > div{
            box-sizing: border-box;
            width: 100%;
        }
        
        &.sticky{
            align-items: flex-start;
            
            & > div{
                height: auto;
            }
        }
        
    }

    & + .columns{
        margin-top: 20px;
    }
}

@include generate-columns(12);