/*!
Theme Name: Teslasuit Portal
Author: m.zeshut@teslasuit.io
Author URI: https://by.linkedin.com/in/zeshut
Version: 1.0.1
Requires at least: 5.8.1
Tested up to: 5.9.3
*/


@import 'settings/functions';
@import 'settings/mixins';
@import 'settings/variables';
@import 'settings/colors';

@import 'settings/reset';

@import 'settings/fonts';
@import 'settings/inputs';
@import 'settings/loader';
@import 'settings/util';

@import 'layout/container';
@import 'layout/flex';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/grid';
@import 'layout/profile';
@import 'layout/sign';
@import 'layout/woocommerce';
@import 'layout/confluence';

@import 'components/sections';

@import 'components/accordeons';
@import 'components/banners';
@import 'components/buttons';
@import 'components/cards';
@import 'components/forms';
@import 'components/forum';
@import 'components/gutenberg';
@import 'components/headings';
@import 'components/images';
@import 'components/landings';
@import 'components/menu';
@import 'components/overlay-panel';
@import 'components/product-info';
@import 'components/search';
@import 'components/tables';
@import 'components/tags';

@import 'settings/keycloak';

// @import 'responsive';