$path: '/wp-content/themes/teslasuit-portal';

$breakpoints: (
    'xs': 359,
    'sm': 575,
    'md': 767,
    'lg': 991,
    'xl': 1199,
    'xxl': 1399,
    'xxxl': 1599,
    'fullhd': 1919,
    '2k': 2559,
    '4k':4095) !default;

$border-radius: 28px;

@include less-than('xl') {
    $border-radius: 24px;
}

@include less-than('md') {
    $border-radius: 20px;
}

$transition: all 0.3s ease-out;

$bordered-card-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1
);