@mixin more-than($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: '#{map-get($breakpoints, $breakpoint)}px') {
            @content;
        }
    }
}

@mixin less-than($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: '#{map-get($breakpoints, $breakpoint)}px') {
            @content;
        }
    }
}

@mixin font($size: 18px, $height: 21px, $weight: normal, $color: $black) {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: $weight;

    font-size: $size;
    line-height: $height;

    color: $color
}

@mixin grid($columns: 3) {
    grid-template-columns: repeat($columns, 1fr);
}

@mixin flex($dir: column, $posx: center, $posy: $posx) {
    display: flex;
    flex-direction: $dir;
    align-items: $posy;
    justify-content: $posx;
}

@mixin animateSVG($colorFrom: $black, $colorTo: $blue, $name: 'black-to-blue') {

    & path[fill],
    & circle[fill] {
        fill: $colorFrom;
        transition: fill 0.3s ease-in-out;
    }

    &:hover path[fill],
    &:hover circle[fill] {
        fill: $colorTo;
    }

    & path[stroke],
    & circle[stroke] {
        stroke: $colorFrom;
        transition: stroke 0.3s ease-in-out;
    }

    &:hover path[stroke],
    &:hover circle[stroke] {
        stroke: $colorTo;
    }
}

@mixin generate-columns($columns: 12) {
    @for $i from 1 through $columns {
        .columns_#{$i} {
            &>.column {
                width: calc(100% / #{$i});

                @for $e from 1 through $columns {
                    &.column_#{$e} {
                        width: calc(100% / #{$i} * #{$e} - 10px);
                    }
                }
            }
        }
    }
}

@mixin rounded() {
    border-radius: $border-radius;

    @include less-than('xl') {
        border-radius: 24px;
    }

    @include less-than('md') {
        border-radius: 20px;
    }
}

@mixin font-responsive($bpMax, $bpMin, $max, $min, $line-height) {

    @include less-than($bpMax) {
        font-size: Clamp(#{$min}px, #{$max / map-get($breakpoints, $bpMax) * 100}vw, #{$max}px) !important;
        line-height: $line-height !important;
    }

}