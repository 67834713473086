.signpage {

    @include flex(row, space-between);
    width: 100%;

    position: relative;

    & #back-to-customer {
        cursor: pointer;
        position: absolute;
        top: 32px;
        right: 32px;
        width: 40px;
        height: 40px;

        @include less-than('lg') {
            top: 16px;
            right: 16px;
        }

        &>img {
            width: 100%;
            height: 100%;
        }
    }

    &__sidebar {
        &_left {
            flex: 0 1 565px;
            height: 100vh;
            position: sticky;
            top: 0;

            background-image: url('#{$path}/assets/img/signpage-sidebar-left.png');
            background-size: cover;
            background-position: bottom right;

            @include less-than('lg') {
                display: none;
            }
        }

        &_right {
            width: 160px;
            background-color: $white;

            @include less-than('xxl') {
                display: none;
            }
        }
    }

    &__content {
        @include font(28px, 38px, 600, $black);
        text-align: center;
        flex: 0 1 605px;
        margin-right: auto;
        margin-left: auto;

        min-height: 100vh;
        box-sizing: border-box;
        padding: 56px 20px;
        @include flex(column, center);

        & .alert {
            width: 100%;
            text-align: center;
            padding: 2px 0 8px 0;
            margin: 16px 32px;
            display: block;
            background: $white;
            box-sizing: border-box;
            border: 1px solid $light;
            border-radius: 12px;

            & .text {
                @include font(18px, 28px, 500, $font);
            }

            &+.signpage__form {
                margin-top: 32px;
            }

            &-error {
                & .text {
                    color: #D3280D;
                }
            }
        }

        #kc-info-message {
            text-align: center;
            margin: 32px 0 0 0;

            & p:not(.instruction)>a {
                @include font(18px, 28px, 300, $white);
                text-align: center;
                cursor: pointer;

                background: $blue  !important;
                border: none;
                border-radius: 12px;
                padding: 9px 24px;

                display: inline-block;

                transition: $transition;

                &:hover {
                    background: $blue_hover;
                    color: $white;
                }
            }
        }



        #kc-reset-password-form{
            width: 100%;
            & #kc-form-options{
                @include font(18px, 28px, 400, $font);
            }

            & input[type="submit"]{
                width: auto;
            }
        }

        & .instruction {
            width: 100%;
            text-align: center;
            margin: 16px 0;
            display: block;
            background: transparent;
            box-sizing: border-box;
            border: 0;
            @include font(18px, 28px, 400, $font);

            &>a {
                color: $blue;
                text-decoration: underline;
                display: inline-block;
            }
        }

        &>form {
            margin-top: 64px;

            & input,
            & select {
                width: 100%;
                box-sizing: border-box;
                margin-bottom: 16px;

                &+span {
                    display: block;
                    margin-bottom: 16px;
                    color: #D3280D;
                }
            }

            & input[type="submit"] {
                @include font(18px, 21px, 300, $white);
                text-align: center;
                cursor: pointer;
                margin-top: 32px;

                background: $blue  !important;
                border: none;
                border-radius: 12px;
                padding: 9px 24px;

                display: inline-block;

                transition: $transition;

                &:hover {
                    background: $blue_hover;
                    color: $white;
                }
            }

            & label {
                @include font(18px, 21px, 400, $black);
                margin: 8px 0;
            }
        }
    }

    &__heading {
        text-align: center;
        width: 100%;

        & .title {
            @include font(50px, 61px, 700, $black);
            margin-bottom: 16px;
        }

        & .subtitle {
            @include font(28px, 34px, 500, $footer);

            & a {
                @include font(28px, 34px, 600, $blue);
                margin-left: 16px;

                @include less-than('lg') {
                    margin-left: 0;
                }
            }
        }
    }

    &__form {
        width: 100%;
        margin-top: 64px;
        text-align: left;

        .input {
            &-group {
                &__label {
                    @include font(18px, 21px, 400, $black);
                    margin-bottom: 8px;
                    margin-top: 8px;
                }
            }
        }

        &>form {
            height: 100%;
            @include flex(column, flex-start);
        }

        @include less-than('lg') {
            .gap_40 {
                gap: 0 !important
            }
        }

        & .form__submitbar {
            @include flex(column, center, center);
            width: 100%;
            margin-top: auto;
            padding-top: 48px;

            @include less-than('sm'){
                padding-top: 8px;
            }

            .btn {
                width: 100%;
                box-sizing: border-box;
            }

            & span {
                @include font(18px, 24px, 400, $font);
                display: block;
                margin-top: 32px;

                @include less-than('sm'){
                    margin-top: 0;
                }
            }

            & .svg {
                cursor: pointer;
                @include animateSVG($blue, $blue_light);

                border-radius: 50%;
                transition: $transition;

                &:hover {
                    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
                }
            }

            & .flex-row {
                gap: 16px;
                margin-top: 16px;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

    }
}