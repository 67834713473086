.profile {

    &__header {
        width: 100%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__head {
        @include flex(row, space-between, center);
        padding-top: 27px;
        padding-bottom: 44px;

        @include less-than('sm') {
            width: 100%;
            @include flex(column, flex-start, flex-start);
        }
    }

    &__title {
        @include flex(row, flex-start, center);
        padding-right: 24px;
        word-break: break-all;

        @include less-than('xs') {
            @include flex(column, flex-start, flex-start);
            padding-right: 0;

        }

        & .title{
            & span{
                @include less-than('lg') {
                    display: block;
                }

            }
        }

        &>.svg {
            margin-right: 32px;

            @include less-than('md') {
                margin-right: 16px;
                width: 48px !important;
                height: 48px !important;
            }
        }

        & .title {
            @include font(38px, 45px, 700, $black);
            margin-bottom: 4px;

            @include less-than('lg') {
                @include font(32px, 48px, 700, $black);
            }

            @include less-than('sm') {
                margin-top: 16px;
                @include font(28px, 40px, 700, $black);
            }
        }

        & .subtitle {
            @include font(18px, 21px, 400, $font);
        }
    }

    &__password {
        @include flex(row, flex-end, center);

        @include less-than('sm') {
            margin-top: 24px;
        }

        &>.svg {
            margin-right: 16px;
            

            // & svg{
            //     & circle{
            //         transition: stroke 0.3s ease-in-out;
            //     }
            //     & path{
            //         transition: fill 0.3s ease-in-out;
            //     }
            // }
        }

        & .title {
            @include font(21px, 25px, 600, $font);
            margin-bottom: 4px;
            transition: $transition;
        }

        &:hover{
            & .title{
                color: $blue;
            }

            // & svg{
            //     & circle{
            //         stroke: $blue !important;
            //     }
            //     & path{
            //         fill: $blue !important
            //     }
            // }
        }

        & .subtitle {
            @include font(18px, 21px, 300, $font);

            @include less-than('sm') {
                display: none;

            }
        }


    }
}

.profile__block {
    @include flex(row, flex-start, center);
    @include rounded;
    border: 1px solid $tabs;
    padding: 24px 32px;

    transition: $transition;

    & .svg {
        margin-right: 32px;
    }

    & .title {
        margin-bottom: 4px;
        transition: $transition;
    }

    & .subtitle {
        @include font(18px, 24px, 300, $font);
    }

    &:hover {
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
        border-color: transparent
    }

    @include animateSVG($black, $blue);

}

.profile-inner-page {
    @include flex(row, space-between, flex-start);
    position: relative;
    flex-wrap: wrap;

    & .sidebar {
        min-width: 385px;
        max-width: 385px;
    }

    & .sidebar+.content {
        margin-left: 0;
    }

    & .content {
        width: calc(100% - (385px + 20px));
        padding-top: 48px;
    }

    @include less-than('xl') {
        & .sidebar {
            min-width: 285px;
            max-width: 285px;
        }

        & .content {
            width: calc(100% - (285px + 20px));
        }
    }

    @include less-than('lg') {
        & .sidebar {
            min-width: 100%;
            max-width: 100%;
        }

        & .content {
            width: 100%;
        }
    }

    & .content {

        & .title-wrapper {
            @include flex(row, space-between, center);

            @include less-than('sm') {
                @include flex(column, flex-start);
            }

            padding-bottom: 24px;
            margin-bottom: 48px;

            border-bottom: 2px solid $black;

            & .title {
                @include font(50px, 61px, 700, $black);

                @include less-than('lg') {
                    @include font(48px, 58px, 700, $black);
                }

                @include less-than('md') {
                    @include font(40px, 48px, 700, $black);
                }
            }

            & .subtitle {
                @include font(50px, 61px, 700, #E6E9EF);

                @include less-than('lg') {
                    @include font(48px, 58px, 700, #E6E9EF);
                }

                @include less-than('md') {
                    @include font(40px, 48px, 700, #E6E9EF);
                }
            }

            & .btn {
                @include less-than('sm') {
                    margin-top: 16px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            &:not(:first-child) {
                padding-top: 80px;
            }

            &+.description {
                margin-bottom: 8px;
            }
        }



    }

    & .sidebar {

        position: sticky;
        top: 80px;

        @include less-than('lg') {
            position: relative;
            top: 0;
        }

        & .backlink {
            margin-bottom: 24px;
            display: inline-block;
        }

        & .title {
            margin-bottom: 8px;
            display: inline-block;
        }

        &__menu {
            // position: sticky;
            // top: 80px;
            margin-top: 40px;

            & ul li {
                margin-bottom: 0;

                & a,
                & span {
                    @include font(21px, 25px, 500, $font);
                    transition: $transition;
                    &:hover {
                        cursor: pointer;
                        color: $blue_hover;
                    }
                }

                @include font(21px, 25px, 500, $font);
                padding: 12px;
                border-left: 2px solid #D1D5DB;
                transition: $transition;

                &.active {
                    border-color: $blue;
                    color: $black;
                    font-weight: 600;

                    & a,
                    & span {
                        color: $black;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.alert {
    width: auto;
    text-align: left;
    padding: 0;
    margin: 0 0 32px 0;
    display: inline-block;
    border: 0;

    &-error {
        & .text {
            color: darkred;
        }
    }

    &-success {
        & .text {
            color: darkgreen;
        }
    }

}

#federated-identities {
    & .row {
        & input[disabled="true"] {
            margin-top: 16px;
            @include font(18px, 24px, 400, $blue);
        }
    }
}

#chatWrapper {
    height: 600px;
    border: 1px solid $light;
    border-radius: 12px;
    overflow: hidden;

    @include flex(column, center);

    &>#chatInitializer {
        text-align: center;
    }
}

#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
    position: relative !important;
    z-index: 2 !important;
}

#webChatHeaderCloseButton {
    display: none !important;
}