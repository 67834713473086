.woocommerce{
    & table.cart{
        border-radius: $border-radius;
        & img{
            width: 150px

        }

        & td,
        & th{
            padding: 16px;
        }

        & .product-name{
            a{
                @include font(23px, 28px, 400, $black);
                display: block;
                margin-bottom: 16px;                   
            }

            & dl.variation *{
                @include font(18px, 21px, 300, $black);       
            }
        }

    }

    & table.shop_table{
        border-radius: $border-radius;

        & td,
        & th{
            padding: 16px;
        }

        & .product-name{
                & a{
                    @include font(23px, 28px, 400, $black);
                }
                display: block;

                & dl.variation{
                    margin-top: 16px; 
                    & *{
                        @include font(18px, 21px, 300, $black);       

                    }
                }

                & .wc-item-meta{
                    margin-top: 16px; 
                    & *{
                        @include font(18px, 21px, 300, $black);       

                    }
                }
        }

        & .product-total{
            @include font(23px, 28px, 400, $black);

        }
    }

    & div.quantity{
        @include font(18px, 21px, 300, $black);                       

        text-align: center;
        width: auto;

        background: $gray;
        border: none;
        border-radius: 12px;
        padding: 9px 5px;

        display: block;

        transition: $transition;

        &:hover{
            background: $blue_hover;
            color: $white;
        }
    }

    .products.loop{
        row-gap: 60px;
        & div.product{
            text-align: left;

            & .img-wrapper{
                @include flex;
                aspect-ratio: 3/2;
                margin-bottom: 16px;
                background-color: $gray;
                @include rounded;
                padding: 64px;
                
                & img{
                    object-fit: contain
                }
            }

            & .woocommerce-loop-product__title{
                @include font(32px, 48px, 700, $black);

                & + p{
                    @include font(21px, 28px, 600, $black);
                }
            }

            & .price{
                display: block;
                margin-top: 32px;
                @include font(32px, 48px, 700, $black);
            }
        }
    }

    & div.product{
        text-align: left;
        padding: 16px;
        & div.summary{
            text-align: left;
        }

        & form.cart{

            & table td {
                padding-left: 30px;
                & select{
                    width: 100%
                }
            }

            & .reset_variations{
                display: block;
                @include font(18px, 21px, 400, $blue);  
                padding-left: 16px;
                padding-top: 16px;
            }
         
        }

        & form.cart .variations{
            margin: 24px 0;
            @include font(18px, 21px, 400, $black);  
            text-align: left;

            & label{
                font-weight: 400 !important
            }

            & tr{
                height: 60px
            }
        }
        
    }

    &-loop-category__title,
    &-loop-product__title{
        @include font(28px, 33px, 400, $black);
    }
    
    & #respond input#submit, 
    & a.button,
    & button.button, 
    & input.button{
        @include font(18px, 21px, 300, $white);                       

        text-align: center;
        cursor: pointer;

        background: $blue;
        border: none;
        border-radius: 12px;
        padding: 9px 24px !important;

        display: block;

        transition: $transition;

        &:hover{
            background: $blue_hover;
            color: $white;
        }

        &:disabled, &:disabled[disabled]{
            background: $blue;
            opacity: 0.5;
            color: $white;
            &:hover{
                color: $white;
                background-color: $blue_hover;
            }
        }
    }

    & a.button.alt,
    & button.button.alt{
        background-color: $blue;

        &.disabled{
            background-color: $blue;
            opacity: 0.5;
            &:hover{
                background-color: $blue_hover;
            }
        }

        &:hover{
            background-color: $blue_hover;
        }
    }

    &-message,
    &-info,
    &-error{
        border-radius: $border-radius;
        transition: $transition;
        border: 1px solid $tabs;
        &:hover{
            box-shadow: $bordered-card-box-shadow;
            border-color: $white;
        }

        @include font(18px, 39px, 300, $black);

        &::before{
            color: $blue
        }
    }

    & form .form-row input.input-text, .woocommerce form .form-row textarea{
        background: $gray;
        color:$black
    }

    & table.shop_table th{
        font-weight: 400 !important
    }

    &  a.remove{
        color: $blue !important;

        &:hover {
            color: #fff!important;
            background: $blue !important;
        }
    }

    & .cart_totals{
        & h2{
            @include font(40px, 48px, 400, $black);
            margin-bottom: 16px;
        }

        & table{
            border-radius: $border-radius;

            & th,
            & td{
                padding: 16px;
                @include font(21px, 28px, 400, $black);
            }
        }
    }

    & #order_review_heading,
    &-billing-fields h3,
    &-additional-fields h3{
        @include font(40px, 48px, 400, $black);
        margin-bottom: 16px;
    }
    #customer_details{
        margin-bottom: 16px;
    }

    #add_payment_method #payment, 
    &-cart #payment, 
    &-checkout #payment{
        @include font(18px, 21px, 400, $black);
        border-radius: $border-radius;
        background-color: $gray;
    }

    &-order{
        @include font(18px, 21px, 400, $black);
        .woocommerce-notice{
            @include font(40px, 48px, 400, $black);
            margin-bottom: 16px;
        }

        &-details{
            margin-top: 16px;
            & h2{
                @include font(40px, 48px, 400, $black);
                margin-bottom: 16px;
            }
        }

        
    }

    &-customer-details{
        & h2{
            @include font(40px, 48px, 400, $black);
            margin-bottom: 16px;
        }

        & address{
            @include font(18px, 28px, 400, $black);
            border-radius: $border-radius !important;
            padding: 16px !important;
        }

        &--phone,
        &--email{
            margin-top: 16px;
            @include font(18px, 21px, 400, $blue);
            &::before{
                line-height: 21px !important;
            }
        }
    }

}

.woocommerce #payment #place_order, .woocommerce-page #payment #place_order{
    float: none;
    width: 100%;
    margin-top: 16px;
}

.payment_box.payment_method_cod{
    display: none !important;
}

#products-generator{
    width: 100%;
}